import React from 'react'
import { SERVICES_TARGET } from '../../static-data/menu-items'
import { Section } from '../Section'
import { SectionHeader } from '../SectionHeader'
import data from './services.json'
import { Icon } from '../Icon'

export interface ServiceSectionProps {}

export const ServiceSection: React.VFC<ServiceSectionProps> = () => {
  return (
    <Section id={SERVICES_TARGET} classes="pb-16">
      <div className="r-container">
        <SectionHeader target={SERVICES_TARGET} headerText="Services" />
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
          {data.services.map((service) => (
            <div key={service.title} className="flex basis-full lg:basis-1/3">
              <div className="card rounded pt-4 pb-8 md:pt-6 md:pb-10 px-12 bg-white">
                <div className="flex justify-center align-middle mb-8 my-4">
                  <div className="w-16 h-16  rounded-full bg-bg-color flex">
                    <span className="m-auto flex text-primary text-3xl  font-bold items-center">
                      <Icon name={service.icon} />
                    </span>{' '}
                  </div>
                </div>
                <h2 className="font-secondary overflow-hidden mb-4 text-center text-lg">
                  {service.title}
                </h2>
                <div className="text-justify overflow-clip opacity-50 text-sm leading-6">
                  {service.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}
