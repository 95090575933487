import React from 'react'
import { Section } from '../Section'
import { SectionHeader } from '../SectionHeader'

import { HOW_WE_WORK } from '../../static-data/menu-items'
import chooseUsData from './data.json'

export interface HowWeWorkProps {}

export const HowWeWorkSection: React.VFC<HowWeWorkProps> = () => {
  const imgSize = 42

  return (
    <div className="relative pb-16 bg-primary-50">
      <Section>
        <div className="r-container">
          <Section classes={`py-4`}>
            <div className="mb-2">
              <SectionHeader headerText="How we work" target={HOW_WE_WORK} />
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 ">
              {chooseUsData.map((it, i) => (
                <div key={i} className="flex flex-col items-center gap-2">
                  <img src={`/images/how_img${i + 1}.png`} alt="" width={imgSize} />
                  <h2 className={`text-2xl my-4 font-bold`}>{it.title}</h2>
                  <p className={` text-center px-0 lg:px-20`}> {it.description}</p>
                </div>
              ))}
            </div>
          </Section>
        </div>
      </Section>
    </div>
  )
}
