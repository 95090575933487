import React, { createContext, useContext, useState } from 'react'
import { HOME_TARGET } from '../static-data/menu-items'
import _scrollTo from '../utils/scrollTo'

export interface CurrentTargetContextProps {
  currentTarget: string
  scrollTo: Function
  setCurrentTarget: (val: string) => void
}

const CurrentTarget = createContext<CurrentTargetContextProps>({
  currentTarget: HOME_TARGET,
  setCurrentTarget: (s) => {},
  scrollTo: () => {},
})

export const CurrentTargetProvider: React.FC = ({ children }) => {
  const [currentTarget, _setCurrentTarget] = useState(HOME_TARGET)
  const [ignoreUpdate, setIgnoreUpdate] = useState(false)

  const setCurrentTarget = (target: string) => {
    console.log('set current', target)
    if (!ignoreUpdate) {
      _setCurrentTarget(target)
    }
  }
  const scrollTo = (target: string | HTMLElement) => {
    console.log('Scroll to ', target)
    const domTarget = _scrollTo(target)
    if (domTarget) setCurrentTarget(domTarget)
  }
  const reset = () => setCurrentTarget(HOME_TARGET)

  return (
    <CurrentTarget.Provider value={{ scrollTo, currentTarget, setCurrentTarget }}>
      {children}
    </CurrentTarget.Provider>
  )
}
const usePageTarget = () => useContext(CurrentTarget)
export default usePageTarget
