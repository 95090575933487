import React from 'react'
import { BRAND } from '../../static-data/brand'
import { BrandEmail } from '../BrandEmail'

export interface IndexHederTopBarProps {}

export const IndexHederTopBar: React.VFC<IndexHederTopBarProps> = () => {
  return (
    <div className="top-header flex text-white text-opacity-80 py-4 px-4 border-b border-b-white border-b-2 border-opacity-50  text-xs  md:text-sm">
      <span className={`hidden sm:inline mr-auto lg:mr-4`}>{BRAND.welcomeMessage}</span>
      <span className={`sm:hidden mr-auto lg:mr-4`}>SFT Consulting Service LLC</span>
      <BrandEmail classes={``} />
    </div>
  )
}
