import { atom, useAtom } from 'jotai'

export interface MenuState {
  isOpen: boolean
}

const menuState = atom<MenuState>({ isOpen: false })

const useMenuState = () => useAtom(menuState)
export default useMenuState
