import React from 'react'
import { Helmet } from 'react-helmet'
import seo from '../../static-data/seo'
import { BRAND } from '../../static-data/brand'

export interface SeoProps {}

export const Seo: React.VFC<SeoProps> = () => {
  return (
    // @ts-ignore
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="robots" content={seo.robots} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:locale" content={`en_US`} />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${seo.url}",
          "name": "${seo.name}",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": ${BRAND.email},
            "contactType": "Customer Support"
          }
        }
      `}
      </script>
    </Helmet>
  )
}
