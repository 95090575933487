export const BRAND = {
  welcomeMessage: 'Welcome to SFT Consulting Service LLC',
  name: 'SFT Consulting Services LLC',
  phone: `(123)-1234-1234`,
  email: 'info@sftconsult.com',
  website: 'https://sftconsult.com',
  adressLine1: '62 Main St New York Mills,',
  adressLine2: 'New York (NY), 13417',
  opening: ['Sun - Fri 8.00AM - 4.00PM', 'Sat CLOSED'],
}
