import React from 'react'
import { Section } from '../Section'
import { SERVICES_TARGET, TESTIMONIALS_TARGET } from '../../static-data/menu-items'
import { SectionHeader } from '../SectionHeader'
import { testimonials } from '../../static-data/testimonials'
import { Testimonial } from './Testimonial'

export type TestimonialSectionProps = {}

export const TestimonialSection: React.VFC<TestimonialSectionProps> = () => {
  return (
    <div className={`relative bg-white pb-16 border-t border-t-transparent border-t-1`}>
      <Section id={TESTIMONIALS_TARGET} classes="">
        <div className="r-container">
          <SectionHeader target={SERVICES_TARGET} headerText="Testimonials" />
          <div className="grid grid-cols-1 2xl:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <Testimonial testimonial={testimonial} />
              </div>
            ))}
          </div>
        </div>
      </Section>
    </div>
  )
}
