import { WithClasses } from '../../types/props-with-children-and-classes'
import { Button } from '../Button'
import React from 'react'
import { Card } from './Card'

export type MainProjectInfoProps = WithClasses<{
  onClick: () => void
  span: number
  header?: string
  description?: string
  cta?: string
}>
export const MainProjectInfo = ({
  classes,
  onClick,
  span,
  header,
  description,
  cta = 'Learn more...',
}: MainProjectInfoProps): JSX.Element => {
  return (
    <Card classes={`p-4 block ${classes}`} backdropActive={false}>
      <div className="text-center font-bold mb-4">
        <span>{header}</span>
      </div>
      <div className="mb-8 text-sm text-center text-justify">{description}</div>
      <div  className="flex justify-center">
        <Button variant="primary" click={onClick} classes={`md:text-sm whitespace-nowrap overflow-hidden overflow-ellipsis`}>
          {cta}
        </Button>
      </div>
    </Card>
  )
}
