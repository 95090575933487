import React, { useEffect, useRef } from 'react'
import { PageWrapper } from '../components/PageWrapper'
import { IndexHeader } from '../components/IndexHeader'
import { ServiceSection } from '../components/ServiceSection'
import { Footer } from '../components/Footer'
import { CurrentTargetProvider } from '../contexts/currentTargetContext'
import { ContactBanner } from '../components/ContactBanner'
import useMenuState from '../state/menu'
import classNames from 'classnames'
import { MobileMenu } from '../components/MobileMenu'
import { Seo } from '../components/Seo'
import { OurProjects } from '../components/OurProjects'
import { TestimonialSection } from '../components/Testimonial/TestimonialSection'
import { HowWeWorkSection } from '../components/HowWeWorkSection'

const IndexPage = () => {
  const pageRef = useRef<HTMLDivElement | undefined>()
  const [{ isOpen }, setMenuState] = useMenuState()
  const pageClass = classNames({ blur: isOpen })

  useEffect(() => {
    pageRef.current?.addEventListener('swipeleft', (e) => console.log(e))
    return () => console.log('die')
  }, [])

  return (
    <CurrentTargetProvider>
      <PageWrapper pageRef={pageRef}>
        <Seo />
        <MobileMenu />
        <div className={pageClass}>
          <IndexHeader />
          <ServiceSection />
          <ContactBanner />
          <OurProjects />
          <HowWeWorkSection />
          <TestimonialSection />
          <Footer />
        </div>
      </PageWrapper>
    </CurrentTargetProvider>
  )
}

export default IndexPage
