import React, { MouseEventHandler } from 'react'
import { Card } from './Card'

export type ImageWrapperProps = {
  src: string
  height?: number
  width?: number
  tx?: number
  ty?: number
  click: (src: string) => void
  classes?: string
}
export const ImageWrapper = ({src, click, classes }: ImageWrapperProps): JSX.Element => {
  const onClick: MouseEventHandler = (ev) => click(src)
  return (
    <Card classes={`flex hover:cursor-pointer ${classes}`} click={onClick} backdropActive>
      <img src={src} alt="Project Image" title={src} className={`object-cover w-full h-full`} />
    </Card>
  )
}
