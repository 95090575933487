import React, { PropsWithChildren } from 'react'
import ScrollTrigger from 'react-scroll-trigger'
import usePageTarget from '../../contexts/currentTargetContext'

export type SectionProps = PropsWithChildren<{
  id?: string
  track?: boolean
  classes?: string
}>

export const Section: React.FC<SectionProps> = ({ id, classes = '', track = false, children }) => {
  const { setCurrentTarget } = usePageTarget()
  return (
    <div id={id} className={classes}>
      {track && id && (
        // @ts-ignore @types/react version mismatch in scrollTrigger (@16) package.json lib package
        <ScrollTrigger onEnter={() => setCurrentTarget(id)} />
      )}
      {children}
    </div>
  )
}
