import React, { CSSProperties, useState } from 'react'
import Modal from 'react-modal'
import { Carousel } from 'react-responsive-carousel'
import './carusel-styles.scss'

import { imageSrc } from '../OurProjects/utils'
import { Icon } from '../Icon'

export enum ImgGroup {
  students = 'students',
  scheduler = 'scheduler',
  report = 'report',
  application = 'application',
}

export interface Image {
  name: string
  thumb: string
  group: ImgGroup | string
  description: {
    title: string
    desc: string
  }
}

export type OurProjectsPreviewModalProps = {
  isOpen: boolean
  images: Image[]
  selectedIndex: number
  onCloseModalClick: () => void
  baseRef: string
  imageStyle?: CSSProperties
  imageContainerStyle?: CSSProperties
}
Modal.setAppElement('#___gatsby')

const OurProjectsPreviewModal = ({
  isOpen,
  selectedIndex,
  onCloseModalClick,
  images,
  baseRef,
  imageStyle = { maxWidth: '100%' },
  imageContainerStyle = { width: '100%', maxHeight: '65vw' },
}: OurProjectsPreviewModalProps): JSX.Element => {
  let ov: HTMLElement
  const [currentVisible, setCurrentVisible] = useState(selectedIndex)
  if (!images.length && !isOpen) return <></>
  const imageDescription = () => {
    if (!images[currentVisible]) return <></>
    return (
      <div>
        <h4 className={`font-bold text-xl text-center mt-3 mb-2 md:mb-4`}>
          {images[currentVisible]?.description?.title}
        </h4>
        <p className={`text-justify md:text-center`}>{images[currentVisible].description.desc}</p>
      </div>
    )
  }
  const afterOpen = () => {
    setCurrentVisible(selectedIndex)
  }
  const afterClose = () => {}
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onCloseModalClick()}
        preventScroll
        shouldFocusAfterRender
        overlayRef={(overlay) => (ov = overlay)}
        onAfterOpen={() => afterOpen()}
        onAfterClose={() => afterClose()}
        className={'image-content'}
        closeTimeoutMS={350}
      >
        <div
          className="fixed left-2 -top-1 md:top-2 md:left-3 opacity-40 hover:opacity-80 text-2xl md:text-3xl z-50 hover:cursor-pointer text-primary"
          onClick={onCloseModalClick}
        >
          <Icon name="times" />
        </div>
        <div className="max-w-full">
          <Carousel
            showThumbs={false}
            selectedItem={selectedIndex}
            onChange={setCurrentVisible}
            infiniteLoop
            showIndicators={false}
          >
            {images?.map((image, index) => (
              <div key={index} className={`md:px-10`} style={{ ...imageContainerStyle }}>
                <img src={imageSrc(baseRef, image.name)} alt="Image" style={{ ...imageStyle }} />
              </div>
            ))}
          </Carousel>
          <div className="mt:2 md:mt-8">{imageDescription()}</div>
        </div>
      </Modal>
    </>
  )
}

OurProjectsPreviewModal.defaultProps = {
  imageStyle: { maxWidth: '100%', maxHeight: '600px', width: 'auto' },
  imageContainerStyle: { width: '100%' },
}

export { OurProjectsPreviewModal }
