import React from 'react'

export interface StackLogoProps {}

export const StackLogo: React.VFC<StackLogoProps> = () => {
  return (
    <>
      {/*<StaticImage src="../../images/logo-stack.png" alt="Mobile Logo" className="w-40"/>*/}
      <svg
        id="_ëîé_1"
        data-name="—ëîé_1"
        xmlns="http://www.w3.org/2000/svg"
        width="319.14"
        height="383.87"
        viewBox="0 0 319.14 383.87"
        className="max-w-full h-auto"
      >
        <defs>
          <style>
            {`.cls-1{fill:var(--color-primary, #334b50);}`}
            {`.cls-2{fill:white;}`}
          </style>
        </defs>
        <path d="M312.37,294c0,.22,0,.44,0,.67" />
        <path d="M312.84,332v.5" />
        <path
          className="cls-1"
          d="M182.14,240.56l-75-1v3.1s4,1,5,3.11,1,86.18.12,94.64c-2.47,3.21-7.12,4.82-7.12,4.82v3.11h28V345.2a21.87,21.87,0,0,1-3.18-1.51,23.29,23.29,0,0,1-4.82-3.67q.49-25.9,1-51.8c8.51.13,17,.2,25.53.66,3.16.17,8.43-.53,10.91,1.77,2.11,2,2.56,5.14,2.56,7.93h4V275.79h-4c.45,0-1.23,5.17-1.48,5.56-1.36,2.13-3.38,2.68-5.74,2.67L140.65,284l-8.64,0c-1.37,0-2.75.06-4.11,0-1.9-.1-1.74-.06-1.76-2.09-.14-12,0-24.05,0-36.07,0,0,40-2.07,45,0s11,8.29,11,8.29h3Z"
        />
        <path
          className="cls-1"
          d="M93.76,303.32a30.78,30.78,0,0,0-10-9.83Q77,289.25,61.61,282.56q-20.28-8.7-25.2-13.06t-4.93-9.61q0-6.87,7.05-12t18.06-5.18a31.59,31.59,0,0,1,25.74,12.38l4.34,9.45a2.14,2.14,0,0,0,1.92,1.22,2.19,2.19,0,0,0,2.11-2.64Q89.1,254,87.48,244.94a43.59,43.59,0,0,0-10.72-4.77,68,68,0,0,0-17-1.86q-17.87,0-28.48,7.8t-10.62,19a19.88,19.88,0,0,0,3,10.91,43.23,43.23,0,0,0,12.17,11.47q5,3.32,21.63,10.55t22.1,12.64q5.5,5.42,5.51,12,0,4.77-3.62,9.82a22.76,22.76,0,0,1-10,7.72,36.44,36.44,0,0,1-14,2.66A34.83,34.83,0,0,1,35.06,335c-5.24-4.16-9.66-14.42-11-17.77-.21-.53-.93-2.36-2.12-2.51a1.7,1.7,0,0,0-1.45.57,1.76,1.76,0,0,0-.34,1.17c0,2.74,0,23.35,0,23.35a70.65,70.65,0,0,0,32.57,8.47c1.17,0,13.77,0,24.63-4.84a34.23,34.23,0,0,0,10.85-7.18A32.63,32.63,0,0,0,91.78,332a27.73,27.73,0,0,0,5.36-16.26A23.59,23.59,0,0,0,93.76,303.32Z"
        />
        <path
          className="cls-1"
          d="M292.3,239.53H200.14l-6,19.68h3a21.62,21.62,0,0,1,5-8.29c4-4,10-5.11,22-7.25a80,80,0,0,1,16-1c-1,0,2,92.21,0,96.36s-8,6.21-8,6.21v3.11h28V345.2s-6-2.07-8-6.21,1-96.36,0-96.36h0l.17,0,0,0a80.08,80.08,0,0,1,16,1c12,2.14,18,3.21,22,7.25a21.39,21.39,0,0,1,4.68,7.39c.06.32.11.62.16.9h3.16Z"
        />
        <path
          className="cls-2"
          d="M198.12,126.72c8.94,2.61,14.5,6.31,14.59,10.51.16,8.51-22.21,15.84-50,16.38s-50.4-5.92-50.57-14.43c-.13-6.59,18.5-11.68,18.3-12.31-.27-.87-36.42,7.72-36.28,18.09s35.9,20.47,67.39,20.33c32.58-.16,68.79-11.42,68.59-21.9C230,136.14,217.27,130,198.12,126.72Z"
        />
        <path
          className="cls-2"
          d="M160.42,44.31a9.33,9.33,0,0,0-3.34.61,7.37,7.37,0,0,1,.59,2.93c0,3.55-2.39,6.42-5.34,6.42a4.56,4.56,0,0,1-2.5-.75,15,15,0,0,0-.49,3.83c0,7.21,5,13.05,11.08,13.05s11.08-5.84,11.08-13.05S166.54,44.31,160.42,44.31Z"
        />
        <path
          className="cls-2"
          d="M161.6,15.31a6.25,6.25,0,0,1,2.19.4,4.81,4.81,0,0,0-.39,1.92c0,2.32,1.56,4.2,3.5,4.2a3.06,3.06,0,0,0,1.64-.49,10.33,10.33,0,0,1,.31,2.51c0,4.72-3.24,8.55-7.25,8.55s-7.26-3.83-7.26-8.55S157.59,15.31,161.6,15.31Z"
        />
        <path
          className="cls-2"
          d="M162.71.11A3.74,3.74,0,0,0,161.38,0a3,3,0,0,1,0,1.18,2.33,2.33,0,0,1-2.64,1.93,1.88,1.88,0,0,1-.88-.52A5.81,5.81,0,0,0,157.27,4c-.69,2.74.65,5.43,3,6s4.77-1.17,5.45-3.91S165,.69,162.71.11Z"
        />
        <path
          className="cls-2"
          d="M176,109c-2.63-2.16-5.88-3.47-8-6.32-2.34-3.13-3.11-7.93-5.91-10.5-2.4,3.16-3.71,7.21-6.15,10.3-2.28,2.88-5.6,4.08-8.19,6.52-1.08,1-8,7.54-7.09,16.42.81,7.76,7.1,12.42,9.92,14.19.52.33,1.1.66,1.1.66a22,22,0,0,0,10.15,3,21.2,21.2,0,0,0,10.23-3s.58-.33,1.1-.66A21.31,21.31,0,0,0,183,125.45C183.84,118.61,179.88,112.28,176,109Zm-19.71,5.58a10.41,10.41,0,0,0-5.75,7.13,10,10,0,0,0,3.83,9.69,1.67,1.67,0,0,1,.36,2.17,1.39,1.39,0,0,1-1.17.67,1.33,1.33,0,0,1-.8-.27,13.27,13.27,0,0,1-5-12.88,13.41,13.41,0,0,1,7.48-9.41,1.37,1.37,0,0,1,1.84.87A1.62,1.62,0,0,1,156.24,114.61Z"
        />
        <path
          className="cls-2"
          d="M44.14,135.11c0,13.39,28,24.91,68.21,30-1.2-.33-2.34-.68-3.44-1-31.08-5.14-52.09-15-52.09-26.24,0-14.21,33.36-26.11,78.21-29.26l4.43-5.8C85.35,105.38,44.14,118.87,44.14,135.11Z"
        />
        <path
          className="cls-2"
          d="M183.13,103.07l4.53,5.93c42.09,3.69,72.79,15.2,72.79,28.83,0,11.51-21.91,21.52-54.09,26.57-1.05.32-2.14.64-3.27.94,41.16-5,70-16.64,70-30.23C273.14,119.4,234.59,106.28,183.13,103.07Z"
        />
        <path
          className="cls-2"
          d="M275.38,103.65c21,8.12,33.57,18.58,33.63,30,.13,26.37-66,48.09-147.82,48.52S13,161.62,12.86,135.25c-.07-14.45,19.78-27.5,51.2-36.42C25.1,108.39-.09,123.47,0,140.33c.15,28.34,71.71,50.94,159.84,50.48S319.28,167,319.14,138.66C319.06,125,302.44,112.7,275.38,103.65Z"
        />
        <path
          className="cls-1"
          d="M33.62,381.58a.12.12,0,0,1,0,.17,6,6,0,0,1-2.42,1.4,10.2,10.2,0,0,1-3.34.47,8.49,8.49,0,0,1-2.7-.34,5.14,5.14,0,0,1-2-1.3,6.3,6.3,0,0,1-1.61-4.73,7.73,7.73,0,0,1,.92-3.55,6.48,6.48,0,0,1,2.26-2.41A9.16,9.16,0,0,1,29.79,370a19.72,19.72,0,0,1,2.12.1,4.68,4.68,0,0,1,1.41.28.1.1,0,0,1,.06.1c0,.1,0,.38-.1.82s-.07.67-.1.94c0,.08,0,.11-.07.11s-.1,0-.11-.08a1.2,1.2,0,0,0-.1-.28,3.49,3.49,0,0,0-.82-.67,7,7,0,0,0-3.06-.52,5.51,5.51,0,0,0-4.14,1.59,6.32,6.32,0,0,0-1.73,4.53A6,6,0,0,0,25,381.58a5.26,5.26,0,0,0,1.88,1.16,7.71,7.71,0,0,0,2.28.27,5,5,0,0,0,2.06-.46,3.47,3.47,0,0,0,1.49-1.22,2.27,2.27,0,0,0,.33-.82c0-.11,0-.17.06-.17s.05,0,.07.09a2.27,2.27,0,0,0,.21.62C33.43,381.2,33.51,381.37,33.62,381.58Z"
        />
        <path
          className="cls-1"
          d="M48.46,376.58A7.21,7.21,0,0,1,47,381.41a7.52,7.52,0,0,1-.86.89,6.6,6.6,0,0,1-4.24,1.32,8,8,0,0,1-3.51-.59,4.75,4.75,0,0,1-1.45-1.1,4.91,4.91,0,0,1-1-1.81A10.23,10.23,0,0,1,35.5,377a8.54,8.54,0,0,1,.37-2.5,5.67,5.67,0,0,1,3.72-3.93,6.66,6.66,0,0,1,2.5-.44,7.9,7.9,0,0,1,3.19.5,4.73,4.73,0,0,1,1.94,1.52A7.29,7.29,0,0,1,48.46,376.58Zm-1.54.53a8.59,8.59,0,0,0-.32-2.46,5.66,5.66,0,0,0-1.15-2.12,4.77,4.77,0,0,0-1.72-1.24,6,6,0,0,0-2.31-.48,3.79,3.79,0,0,0-2.16.48,4.76,4.76,0,0,0-1.66,1.89,10,10,0,0,0,.48,7.92,4.54,4.54,0,0,0,2,1.63,5.3,5.3,0,0,0,2.23.47,4.47,4.47,0,0,0,2.52-.76,4.54,4.54,0,0,0,1.54-2A8.1,8.1,0,0,0,46.92,377.11Z"
        />
        <path
          className="cls-1"
          d="M64,370.63c0,.07,0,.11-.09.11H62.59q-.21,0-.21.27c0,.05,0,.94,0,2.66s0,2.65,0,2.79,0,1.31,0,3.49,0,3.34,0,3.46c0,.31,0,.46-.12.46a.25.25,0,0,1-.22-.12q-.9-1.19-5.27-6.77c-1-1.38-2.25-2.93-3.65-4.63-.09-.08-.15-.06-.19.07s-.06,1.28-.14,3.59-.11,3.79-.11,4.38c0,1.73.17,2.6.52,2.6h1.3c.06,0,.09,0,.09.08v.36c0,.05,0,.08-.07.08H50.57c-.09,0-.13,0-.13-.06v-.36c0-.07,0-.1.13-.1h1.2a.14.14,0,0,0,.16-.15v-1.43c0-.39,0-2,.14-4.68s.14-4.13.14-4.18c0-1.2-.41-1.81-1.24-1.81h-.69c-.05,0-.07,0-.07-.09v-.33a.08.08,0,0,1,.09-.09h3.09a.27.27,0,0,1,.22.15c0,.11.59.84,1.61,2.2s1.59,2.13,1.73,2.28c1.45,1.94,3,3.95,4.63,6l.08,0q.08,0,.09-.27c0-.13,0-1.28,0-3.46s0-3.37,0-3.55c-.1-1.92-.33-2.88-.7-2.88H59.69c-.09,0-.13,0-.13-.09v-.33c0-.06,0-.09.11-.09h4.26c.08,0,.11,0,.11.07Z"
        />
        <path
          className="cls-1"
          d="M73.46,379.85a3.62,3.62,0,0,1-1,2.66,4.36,4.36,0,0,1-3.2,1.09,5.27,5.27,0,0,1-3.48-.89.55.55,0,0,1-.12-.4c0-.05,0-.43,0-1.15s0-1.1,0-1.15a.12.12,0,0,1,.14-.13h.4c.08,0,.12.05.14.13s0,.29.06.62a2.37,2.37,0,0,0,.78,1.72,2.74,2.74,0,0,0,1,.52,3.51,3.51,0,0,0,1.15.22,2.63,2.63,0,0,0,2.6-1.3,3.41,3.41,0,0,0,.3-1.51,1.9,1.9,0,0,0-.56-1.37,5.8,5.8,0,0,0-2.18-1.2,8.5,8.5,0,0,1-2.5-1.26,3.21,3.21,0,0,1-1-2.6,3.52,3.52,0,0,1,1.34-2.8,4,4,0,0,1,2.7-.88,5.53,5.53,0,0,1,2,.37,2.87,2.87,0,0,1,.89.49.34.34,0,0,1,.15.26,13.32,13.32,0,0,1,.11,2q0,.21-.06.21h-.47s-.07,0-.09-.12-.09-.44-.14-.74a1.43,1.43,0,0,0-.68-1,4.48,4.48,0,0,0-2.28-.56,2.54,2.54,0,0,0-1.72.49,2.38,2.38,0,0,0-.58,1.83,2,2,0,0,0,.54,1.4,3.25,3.25,0,0,0,1,.62L70,376c.84.36,1.33.59,1.48.67a4.64,4.64,0,0,1,1.24,1A3.3,3.3,0,0,1,73.46,379.85Z"
        />
        <path
          className="cls-1"
          d="M89.05,370.63c0,.07,0,.11-.06.11h-.56c-.5,0-.78,0-.84,0a1.88,1.88,0,0,0,0,.43c0,.42,0,1.07-.06,2,0,1.25,0,2.93,0,5,.07,3.75-1.48,5.62-4.63,5.62a10.43,10.43,0,0,1-3.31-.44,3.08,3.08,0,0,1-1.82-1.54,6.89,6.89,0,0,1-.52-2.77q0-1.2,0-6c0-1.52-.13-2.29-.38-2.29H75.51c-.07,0-.11,0-.11-.09v-.31a.1.1,0,0,1,.11-.11H80.3c.08,0,.12,0,.12.07v.31c0,.09,0,.13-.08.13H79c-.08,0-.13.06-.14.17,0,.27-.06,2.41-.06,6.44a21.59,21.59,0,0,0,.12,2.84,3,3,0,0,0,1.3,2.12,5.17,5.17,0,0,0,2.92.72,4,4,0,0,0,2.94-1.1q.84-.84.84-3.33c0-2.18,0-3.8-.06-4.85-.08-2-.29-3-.63-3H85s-.08,0-.09,0a.07.07,0,0,1,0-.06v-.33c0-.06,0-.09.11-.09h4c.06,0,.1,0,.1.09Z"
        />
        <path
          className="cls-1"
          d="M101.1,382.13a2.54,2.54,0,0,1-.31.62,5.43,5.43,0,0,1-.34.64c-.06.08-.11.12-.17.12H91a.1.1,0,0,1-.11-.1v-.32c0-.07,0-.1.09-.1h1.47q.18,0,.18-.21s0-.45,0-1.18,0-2.34,0-4.84,0-3.81,0-3.94c0-1.38-.12-2.08-.38-2.08h-1.1c-.09,0-.14,0-.14-.11v-.29c0-.07,0-.11.12-.11H96.4c.07,0,.1,0,.1.07v.35c0,.06,0,.09-.08.09h-2a.17.17,0,0,0-.18.14c0,.09,0,1.68-.09,4.77,0,2.9-.06,4.55-.06,5,0,1.5.17,2.25.52,2.25,2.1,0,3.31,0,3.62,0a5.51,5.51,0,0,0,2.45-.69A1.08,1.08,0,0,1,101,382,.19.19,0,0,1,101.1,382.13Z"
        />
        <path
          className="cls-1"
          d="M113.23,370.36a11.76,11.76,0,0,1-.31,1.43c0,.09-.06.14-.11.14s-.13-.06-.19-.16a1.12,1.12,0,0,0-.78-.57,19.15,19.15,0,0,0-3.48-.21c-.1,0-.16,0-.16.12s0,1.61-.06,4.56-.06,4.34-.06,4.54c0,1.85.16,2.78.48,2.78h1.68a.07.07,0,0,1,.07.08v.34c0,.06,0,.1-.07.1h-5.37c-.08,0-.11,0-.11-.1v-.32c0-.07,0-.1.11-.1h1.57c.12,0,.18-.08.18-.25,0-1.37,0-2.12,0-2.25s0-1.21,0-3.3.05-3.17.05-3.26c0-2-.11-2.94-.34-2.94a15.21,15.21,0,0,0-3.55.28,1.54,1.54,0,0,0-1,.54c-.07.12-.14.17-.2.17s-.12,0-.12-.07a.56.56,0,0,1,.07-.26c.18-.66.32-1.14.43-1.46,0-.09.1-.12.22-.1s.81.06,2,.12c1.41.06,2.54.09,3.41.09,2.79,0,4.37,0,4.75,0a3.74,3.74,0,0,0,.49,0l.22,0C113.18,370.21,113.23,370.26,113.23,370.36Z"
        />
        <path
          className="cls-1"
          d="M119.62,383.43c0,.05,0,.08-.06.08h-4.74c-.06,0-.09,0-.1,0a.08.08,0,0,1,0-.07v-.3a.2.2,0,0,1,0-.11h1.4c.09,0,.14-.09.14-.28l0-10.1c0-1.23-.12-1.85-.34-1.85h-1.24c-.09,0-.14,0-.14-.09v-.35c0-.05,0-.07.1-.07h4.73c.06,0,.09,0,.09.07v.36c0,.06,0,.08-.11.08H118q-.18,0-.18.21,0,8.86,0,9.16c0,1,0,1.51,0,1.65,0,.81.18,1.21.39,1.21h1.38a.07.07,0,0,1,.08.08Z"
        />
        <path
          className="cls-1"
          d="M135.14,370.63c0,.07,0,.11-.1.11h-1.36c-.13,0-.2.09-.2.27s0,.94,0,2.66,0,2.65,0,2.79,0,1.31,0,3.49,0,3.34,0,3.46c0,.31,0,.46-.11.46a.27.27,0,0,1-.23-.12q-.89-1.19-5.26-6.77c-1-1.38-2.26-2.93-3.66-4.63-.09-.08-.15-.06-.18.07s-.07,1.28-.15,3.59-.1,3.79-.1,4.38c0,1.73.17,2.6.51,2.6h1.3c.07,0,.1,0,.1.08v.36a.07.07,0,0,1-.08.08h-3.84q-.12,0-.12-.06v-.36c0-.07,0-.1.12-.1h1.2c.11,0,.16-.05.16-.15v-1.43c0-.39,0-2,.14-4.68s.14-4.13.14-4.18c0-1.2-.41-1.81-1.24-1.81h-.68s-.08,0-.08-.09v-.33c0-.06,0-.09.1-.09h3.09a.25.25,0,0,1,.21.15c.06.11.6.84,1.62,2.2s1.59,2.13,1.72,2.28c1.46,1.94,3,3.95,4.64,6a.11.11,0,0,0,.08,0s.07-.09.09-.27v-3.46c0-2.19,0-3.37,0-3.55-.1-1.92-.34-2.88-.71-2.88h-1.34q-.12,0-.12-.09v-.33c0-.06,0-.09.1-.09H135c.08,0,.12,0,.12.07Z"
        />
        <path
          className="cls-1"
          d="M149.56,377.77a.11.11,0,0,1-.12.12h-1.39a.11.11,0,0,0-.12.12c0-.05,0,.15,0,.62s0,.92,0,1.36c0,.81,0,1.55.07,2.24,0,.09,0,.14-.11.15a4.75,4.75,0,0,0-1.43.51,8.6,8.6,0,0,1-3.55.75,9.34,9.34,0,0,1-3-.33,5.37,5.37,0,0,1-2.5-1.92,7.73,7.73,0,0,1,.63-8.59,7.19,7.19,0,0,1,3.55-2.17,11.6,11.6,0,0,1,3.36-.4,10.19,10.19,0,0,1,3.16.36.14.14,0,0,1,.12.13,3.76,3.76,0,0,1,0,.44c0,.3-.06.47-.07.51-.06.67-.11,1.06-.13,1.17s-.06.11-.11.11-.14,0-.14-.09a2.4,2.4,0,0,0,0-.46,1.23,1.23,0,0,0-.52-.65,8.18,8.18,0,0,0-5.56-.45,5.34,5.34,0,0,0-2.07,1.43,6.82,6.82,0,0,0,.11,8.79,4.76,4.76,0,0,0,2.15,1.29,8.24,8.24,0,0,0,2.15.22,4.19,4.19,0,0,0,2.33-.76.3.3,0,0,0,.09-.18v-1.73c0-.62,0-1.05,0-1.3q-.08-1.17-.21-1.17H144c-.08,0-.11-.06-.11-.18v-.2c0-.1,0-.15.13-.15h5.4a.12.12,0,0,1,.14.13Z"
        />
        <path
          className="cls-1"
          d="M163.84,379.85a3.63,3.63,0,0,1-1,2.66,4.34,4.34,0,0,1-3.2,1.09,5.27,5.27,0,0,1-3.48-.89.55.55,0,0,1-.11-.4c0-.05,0-.43,0-1.15s0-1.1,0-1.15a.12.12,0,0,1,.13-.13h.4a.13.13,0,0,1,.14.13c0,.09,0,.29.06.62a2.45,2.45,0,0,0,.78,1.72,2.8,2.8,0,0,0,1,.52,3.41,3.41,0,0,0,1.14.22,2.65,2.65,0,0,0,2.61-1.3,3.57,3.57,0,0,0,.29-1.51,1.9,1.9,0,0,0-.56-1.37,5.72,5.72,0,0,0-2.18-1.2,8.5,8.5,0,0,1-2.5-1.26,3.24,3.24,0,0,1-1-2.6,3.53,3.53,0,0,1,1.35-2.8,4,4,0,0,1,2.69-.88,5.49,5.49,0,0,1,2,.37,2.76,2.76,0,0,1,.89.49.38.38,0,0,1,.16.26,15.09,15.09,0,0,1,.1,2c0,.14,0,.21,0,.21H163c-.05,0-.08,0-.1-.12s-.09-.44-.14-.74a1.4,1.4,0,0,0-.68-1,4.48,4.48,0,0,0-2.28-.56,2.56,2.56,0,0,0-1.72.49,2.38,2.38,0,0,0-.58,1.83,2,2,0,0,0,.55,1.4,3.06,3.06,0,0,0,1,.62l1.27.52c.84.36,1.34.59,1.49.67a4.81,4.81,0,0,1,1.24,1A3.3,3.3,0,0,1,163.84,379.85Z"
        />
        <path
          className="cls-1"
          d="M176.16,382.11c-.19.37-.4.8-.66,1.28a.23.23,0,0,1-.2.12h-9.24c-.07,0-.1,0-.1-.12v-.3c0-.07,0-.1.08-.1h1.45c.13,0,.19-.07.19-.21s0-1,0-3,0-2.94,0-3,0-.73,0-2,0-2,0-2c0-1.33-.13-2-.38-2h-1.18c-.07,0-.1,0-.1-.09v-.33q0-.09.12-.09h8.74l.47,0,.29,0c.09,0,.12,0,.1.09-.09.36-.21.78-.34,1.24-.06.15-.11.23-.16.23l-.14-.15a2.24,2.24,0,0,0-1.14-.5,37,37,0,0,0-4.64-.23.16.16,0,0,0-.16.12c0,.08,0,1.69,0,4.84,0,.13.05.2.16.2H174c.09,0,.14,0,.16-.15s0-.31.08-.62.06-.5.06-.53,0-.19.12-.19h.15c.07,0,.11.06.11.17s0,.3,0,.81,0,.83,0,.93,0,.44,0,1,0,.9,0,.94,0,.21-.1.21h-.16c-.07,0-.1,0-.12-.13,0-.42-.06-.91-.1-1.49,0-.13-.08-.19-.18-.19l-2.41,0-2.37,0q-.12,0-.12.18v3.68c0,1.56.17,2.34.5,2.34q4.57,0,6.08-.7l.44-.25C176.16,382,176.18,382,176.16,382.11Z"
        />
        <path
          className="cls-1"
          d="M190.38,383.39c0,.08,0,.12-.08.12h-3.17a.43.43,0,0,1-.25-.12,13.41,13.41,0,0,1-1.36-1.64l-1.06-1.43q-.59-.87-1.62-2.73c0-.11-.1-.17-.15-.17-.31,0-.85,0-1.63-.11-.1,0-.15,0-.15.09s0,.54,0,1.24,0,1.44,0,2.21c0,1.43.14,2.14.44,2.14h1.74c.06,0,.09,0,.09.1v.32c0,.06,0,.1-.08.1H178c-.06,0-.1,0-.11,0a.14.14,0,0,1,0-.07v-.3c0-.06,0-.1,0-.11h1.36c.1,0,.16-.1.16-.3v-9.26c0-1.79-.13-2.69-.35-2.69h-1.24c-.06,0-.09,0-.09-.07v-.37c0-.05,0-.07.07-.07h2.92l1.12,0,1.1,0a7.24,7.24,0,0,1,1.24.1,3.89,3.89,0,0,1,2.17,1,3,3,0,0,1,1,2.3,3.68,3.68,0,0,1-.94,2.59,4.43,4.43,0,0,1-1.89,1.07.1.1,0,0,0-.06.12,10.89,10.89,0,0,0,2,2.88c.54.61.93,1.05,1.18,1.31a9.4,9.4,0,0,0,1.11,1,2.55,2.55,0,0,0,1.56.46c.11,0,.16,0,.16.08Zm-4.74-9.33a2.79,2.79,0,0,0-.86-2.12,4.88,4.88,0,0,0-3.47-1.13c-.22,0-.34.05-.36.14,0,1.87,0,3,0,3.49v2.23c0,.07,0,.12.13.14s.57,0,1.24,0a6.88,6.88,0,0,0,.95-.08l.47-.08A2.43,2.43,0,0,0,185.64,374.06Z"
        />
        <path
          className="cls-1"
          d="M203.59,370.65q0,.09-.06.09h-.62a.32.32,0,0,0-.28.15q-1,2.52-2.24,5.58c-.73,1.79-1.64,4-2.76,6.77-.13.31-.23.46-.3.46s-.17-.11-.26-.33q-2-5-2.76-6.7c-.36-.86-.71-1.73-1.07-2.59a21.56,21.56,0,0,0-1.45-3,.74.74,0,0,0-.54-.35h-.34c-.06,0-.09,0-.1,0a.07.07,0,0,1,0-.06v-.33a.08.08,0,0,1,.09-.09h4.27c.08,0,.12,0,.12.07v.33c0,.07,0,.11-.08.11H194a.22.22,0,0,0-.24.25,1.42,1.42,0,0,0,.1.46l1.92,4.64c1.19,3,1.85,4.68,2,4.9s.1.2.15.2.08,0,.13-.12.5-1.13,1.14-2.76c.31-.84.64-1.68,1-2.53q.07-.15.63-1.65c.37-1,.58-1.59.63-1.75a4.28,4.28,0,0,0,.24-1.26c0-.25-.1-.38-.31-.38h-1.09c-.09,0-.14,0-.14-.09v-.33c0-.06,0-.09.08-.09h3.34c.07,0,.1,0,.1.07Z"
        />
        <path
          className="cls-1"
          d="M209.91,383.43c0,.05,0,.08-.06.08h-4.73c-.06,0-.1,0-.11,0a.14.14,0,0,1,0-.07v-.3c0-.06,0-.1,0-.11h1.4c.09,0,.13-.09.13-.28l0-10.1c0-1.23-.11-1.85-.34-1.85H205c-.09,0-.14,0-.14-.09v-.35c0-.05,0-.07.1-.07h4.74c.06,0,.09,0,.09.07v.36c0,.06,0,.08-.11.08h-1.38c-.11,0-.17.07-.17.21,0,5.91,0,9,0,9.16,0,1,0,1.51,0,1.65,0,.81.17,1.21.39,1.21h1.37c.06,0,.08,0,.08.08Z"
        />
        <path
          className="cls-1"
          d="M223.64,381.58a.15.15,0,0,1,0,.17,6.19,6.19,0,0,1-2.42,1.4,10.25,10.25,0,0,1-3.34.47,8.6,8.6,0,0,1-2.71-.34,5.21,5.21,0,0,1-2-1.3,6.29,6.29,0,0,1-1.6-4.73,7.6,7.6,0,0,1,.92-3.55,6.37,6.37,0,0,1,2.25-2.41A9.21,9.21,0,0,1,219.8,370a19.59,19.59,0,0,1,2.12.1,4.78,4.78,0,0,1,1.42.28.11.11,0,0,1,0,.1c0,.1,0,.38-.09.82s-.08.67-.1.94c0,.08,0,.11-.08.11s-.09,0-.1-.08a1.38,1.38,0,0,0-.11-.28,3.13,3.13,0,0,0-.81-.67,7,7,0,0,0-3.06-.52,5.51,5.51,0,0,0-4.14,1.59,6.29,6.29,0,0,0-1.74,4.53,6,6,0,0,0,1.84,4.65,5.14,5.14,0,0,0,1.87,1.16,7.83,7.83,0,0,0,2.29.27,5,5,0,0,0,2.06-.46,3.44,3.44,0,0,0,1.48-1.22,2.27,2.27,0,0,0,.33-.82c0-.11,0-.17.06-.17s0,0,.07.09a3.23,3.23,0,0,0,.21.62C223.44,381.2,223.53,381.37,223.64,381.58Z"
        />
        <path
          className="cls-1"
          d="M235.75,382.11c-.18.37-.4.8-.65,1.28a.24.24,0,0,1-.21.12h-9.23c-.07,0-.1,0-.1-.12v-.3c0-.07,0-.1.08-.1h1.44a.18.18,0,0,0,.2-.21s0-1,0-3,0-2.94,0-3,0-.73,0-2,0-2,0-2c0-1.33-.13-2-.38-2h-1.18c-.07,0-.1,0-.1-.09v-.33q0-.09.12-.09h8.74l.47,0,.29,0q.12,0,.09.09c-.09.36-.2.78-.34,1.24-.05.15-.1.23-.15.23l-.14-.15a2.24,2.24,0,0,0-1.14-.5,37.14,37.14,0,0,0-4.64-.23.16.16,0,0,0-.16.12c0,.08,0,1.69,0,4.84,0,.13.06.2.17.2h4.78c.09,0,.14,0,.16-.15s0-.31.07-.62.06-.5.07-.53,0-.19.11-.19h.15c.08,0,.12.06.12.17s0,.3,0,.81,0,.83,0,.93,0,.44,0,1,0,.9,0,.94,0,.21-.11.21H234c-.07,0-.11,0-.12-.13,0-.42-.06-.91-.1-1.49,0-.13-.08-.19-.18-.19l-2.41,0-2.37,0c-.08,0-.13.06-.13.18v3.68q0,2.34.51,2.34c3,0,5.07-.23,6.08-.7l.43-.25C235.76,382,235.78,382,235.75,382.11Z"
        />
        <path
          className="cls-1"
          d="M245.4,379.85a3.63,3.63,0,0,1-1,2.66,4.34,4.34,0,0,1-3.2,1.09,5.27,5.27,0,0,1-3.48-.89.55.55,0,0,1-.12-.4c0-.05,0-.43,0-1.15V380a.12.12,0,0,1,.13-.13h.4a.13.13,0,0,1,.14.13c0,.09,0,.29.06.62a2.45,2.45,0,0,0,.78,1.72,2.8,2.8,0,0,0,1,.52,3.41,3.41,0,0,0,1.14.22,2.65,2.65,0,0,0,2.61-1.3,3.57,3.57,0,0,0,.29-1.51,1.9,1.9,0,0,0-.56-1.37,5.72,5.72,0,0,0-2.18-1.2,8.5,8.5,0,0,1-2.5-1.26,3.24,3.24,0,0,1-1-2.6,3.53,3.53,0,0,1,1.35-2.8,4,4,0,0,1,2.69-.88,5.49,5.49,0,0,1,2,.37,2.76,2.76,0,0,1,.89.49.38.38,0,0,1,.16.26,15.09,15.09,0,0,1,.1,2c0,.14,0,.21,0,.21h-.47c-.05,0-.08,0-.1-.12s-.09-.44-.14-.74a1.4,1.4,0,0,0-.68-1,4.48,4.48,0,0,0-2.28-.56,2.56,2.56,0,0,0-1.72.49,2.38,2.38,0,0,0-.58,1.83,2,2,0,0,0,.55,1.4,3.06,3.06,0,0,0,1,.62l1.27.52c.84.36,1.33.59,1.48.67a4.49,4.49,0,0,1,1.24,1A3.25,3.25,0,0,1,245.4,379.85Z"
        />
        <path
          className="cls-1"
          d="M262.23,382.13a2.54,2.54,0,0,1-.31.62,5.43,5.43,0,0,1-.34.64.21.21,0,0,1-.17.12h-9.29c-.08,0-.12,0-.12-.1v-.32c0-.07,0-.1.1-.1h1.46q.18,0,.18-.21s0-.45,0-1.18,0-2.34,0-4.84,0-3.81,0-3.94c0-1.38-.13-2.08-.38-2.08h-1.11c-.09,0-.13,0-.13-.11v-.29a.1.1,0,0,1,.11-.11h5.29c.06,0,.09,0,.09.07v.35c0,.06,0,.09-.07.09h-2a.16.16,0,0,0-.17.14c0,.09-.06,1.68-.1,4.77,0,2.9-.06,4.55-.06,5,0,1.5.17,2.25.52,2.25,2.1,0,3.31,0,3.62,0a5.47,5.47,0,0,0,2.45-.69.93.93,0,0,1,.29-.15A.2.2,0,0,1,262.23,382.13Z"
        />
        <path
          className="cls-1"
          d="M273.43,382.13a2.54,2.54,0,0,1-.31.62,5.43,5.43,0,0,1-.34.64c-.06.08-.11.12-.17.12h-9.3c-.08,0-.11,0-.11-.1v-.32c0-.07,0-.1.09-.1h1.47c.12,0,.17-.07.17-.21s0-.45,0-1.18,0-2.34,0-4.84,0-3.81,0-3.94c0-1.38-.12-2.08-.38-2.08h-1.1c-.09,0-.14,0-.14-.11v-.29c0-.07,0-.11.12-.11h5.28c.07,0,.1,0,.1.07v.35c0,.06,0,.09-.08.09h-2a.17.17,0,0,0-.18.14q0,.14-.09,4.77c0,2.9-.06,4.55-.06,5q0,2.25.51,2.25c2.11,0,3.32,0,3.63,0a5.51,5.51,0,0,0,2.45-.69,1.08,1.08,0,0,1,.28-.15A.19.19,0,0,1,273.43,382.13Z"
        />
        <path
          className="cls-1"
          d="M286.45,381.58a.12.12,0,0,1,0,.17,6.11,6.11,0,0,1-2.42,1.4,10.2,10.2,0,0,1-3.34.47,8.49,8.49,0,0,1-2.7-.34,5.24,5.24,0,0,1-2-1.3,6.33,6.33,0,0,1-1.6-4.73,7.73,7.73,0,0,1,.92-3.55,6.4,6.4,0,0,1,2.26-2.41,9.15,9.15,0,0,1,5.06-1.28,19.46,19.46,0,0,1,2.12.1,4.78,4.78,0,0,1,1.42.28.1.1,0,0,1,.06.1c0,.1,0,.38-.1.82s-.07.67-.1.94c0,.08,0,.11-.08.11a.09.09,0,0,1-.1-.08,1,1,0,0,0-.11-.28,3.13,3.13,0,0,0-.81-.67,7,7,0,0,0-3.06-.52,5.51,5.51,0,0,0-4.14,1.59,6.29,6.29,0,0,0-1.74,4.53,6,6,0,0,0,1.84,4.65,5.23,5.23,0,0,0,1.87,1.16A7.83,7.83,0,0,0,282,383a5,5,0,0,0,2.06-.46,3.44,3.44,0,0,0,1.48-1.22,2.14,2.14,0,0,0,.34-.82c0-.11,0-.17.06-.17s0,0,.06.09a2.69,2.69,0,0,0,.22.62C286.25,381.2,286.34,381.37,286.45,381.58Z"
        />
      </svg>
    </>
  )
}
