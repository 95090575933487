import React, { useState } from 'react'
import { SectionHeader } from '../SectionHeader'
import { OUR_PROJECTS } from '../../static-data/menu-items'
import { Section } from '../Section'
import {
  Image,
  OurProjectsPreviewModal,
  OurProjectsPreviewModalProps,
} from '../OurProjectsPreviewModal'
import { DocumentPortalGallery } from './document-portal-gallery/DocumentPortalGallery'
import { TokGallery } from './tok-gallery/tok-gallery'
import { SlpMobileGallery } from './slp-mobile-gallery/SlpMobileGallery'

export interface OurProjectsProps {}

export const OurProjects: React.VFC<OurProjectsProps> = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [images, setImages] = useState<Image[]>([])
  const [baseRef, setBaseRef] = useState<string>('')
  const [modalProps, setModalProps] = useState<Partial<OurProjectsPreviewModalProps>>(
    OurProjectsPreviewModal.defaultProps,
  )

  const openModal = (
    index = 0,
    images: Image[],
    baseRef: string,
    modalProps: Partial<OurProjectsPreviewModalProps> = OurProjectsPreviewModal.defaultProps,
  ) => {
    setModalProps(modalProps)
    setSelectedIndex(index)
    setBaseRef(baseRef)
    setImages(images)
    setTimeout(() => setModalIsOpen(true), 100)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <>
      <OurProjectsPreviewModal
        images={images}
        isOpen={modalIsOpen}
        selectedIndex={selectedIndex}
        baseRef={baseRef}
        onCloseModalClick={closeModal}
      />
      <Section id={OUR_PROJECTS} classes={`pb-16`}>
        <div className={`px-4 md:px-8 xl:px-16`}>
          <SectionHeader target={OUR_PROJECTS} headerText="Our Projects" />
          <div className="project-section">
            <TokGallery openModal={openModal} />
          </div>
          <hr className={`my-8 border-2 border-primary-200 w-16 m-auto`} />
          <div className="project-section">
            <DocumentPortalGallery openModal={openModal} />
          </div>
          <hr className={`my-8 border-2 border-primary-200 w-16 m-auto`} />
          <div className="project-section">
            <SlpMobileGallery openModal={openModal} />
          </div>
        </div>
      </Section>
    </>
  )
}
