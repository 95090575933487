import isString from './isString'

function scrollTo(elem: string | HTMLElement) {
  let target: string
  let element: HTMLElement
  if (isString(elem)) {
    element = (document.querySelector(elem) ?? document.getElementById(elem)) as HTMLElement
    target = elem
  } else {
    element = elem
    target = elem.id
  }
  element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  return target
}

export default scrollTo
