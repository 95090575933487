import React from 'react'

export interface SectionHeaderProps {
  headerText: string
  target?: string
  center?: boolean
}

export const SectionHeader: React.VFC<SectionHeaderProps> = ({
  headerText,
  target,
  center = true,
}) => {
  return (
    <div id={target} className={`mt-2 mb-6 lg:my-12 ${center && 'text-center'}`}>
      <h2 className="font-secondary my-3 text-4xl ">{headerText}</h2>
      <span className="inline-block w-10 mt-6 border-b-2 border-solid border-primary"></span>
    </div>
  )
}
