import React from 'react'
import { LogoInline } from '../LogoInline'
import { BRAND } from '../../static-data/brand'
import { BrandEmail } from '../BrandEmail'

export interface FooterProps {}

export const Footer: React.VFC<FooterProps> = () => {
  const { email, phone } = BRAND
  return (
    <div className="flex py-8 bg-header-image bg-cover bg-center items-center relative px-4 lg:px-20">
      <div className="logo h-16">
        <LogoInline />
        <span className={`inline-block hidden lg:inline-block ml-12`}></span>
      </div>
      <div className="ml-auto text-white flex-col sm:flex-row flex text-sm">
        <BrandEmail />
        {/*<a href={`tel:${phone}`} className="mx-2">*/}
        {/*  <Icon name="phone" /> {phone}*/}
        {/*</a>*/}
      </div>
    </div>
  )
}
