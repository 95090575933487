import React from 'react'
import usePageTarget from '../../contexts/currentTargetContext'
import { CONTACT_TARGET, HOME_TARGET, SERVICES_TARGET } from '../../static-data/menu-items'
import { Button } from '../Button'
import { LogoInline } from '../LogoInline'
import { NavList } from '../NavList'
import { StackLogo } from '../StackLogo'
import './styles.css'
import { BRAND } from '../../static-data/brand'
import { IndexHederTopBar } from '../IndexHederTopBar'

// @ts-ignore
export interface IndexHeaderProps {}

export const IndexHeader: React.FC<IndexHeaderProps> = ({ children }) => {
  const { scrollTo } = usePageTarget()
  return (
    <div
      id={HOME_TARGET}
      className="header bg-header-image bg-cover bg-center h-min lg:flex-col min-h-screen"
    >
      <IndexHederTopBar />
      <div className="menu hidden lg:flex lg:mt-4 h-20  px-8 lg:px-14 items-center">
        <div className="logo h-16">
          <LogoInline />
        </div>
        <div className="navbar ml-auto mx-8 mb-2">
          <NavList active="home" />
        </div>
      </div>
      <div className="r-container flex mx-auto lg:mt-52 2xl:mt-60 items-center lg:items-start">
        <div className="flex flex-col py-8 px-4 w-full">
          <div className="logo flex justify-center lg:hidden mb-12 lg:mb-5 md:mt-20">
            <div className="w-40">
              <StackLogo />
            </div>
          </div>
          <div className="header-text mx-3 section-margin lg:w-6/12">
            <h1 className="font-secondary text-white text-4xl leading-10 text-center section-margin lg:text-left lg:mb-8">
              Streamline operations by harnessing <span className="text-primary">technology</span>
            </h1>
            <p className="text-center text-white opacity-50 text mb-4 lg:text-left">
              Expert Strategy in defining the most ideal workflow and software needed to automate
              and scale your operations.
            </p>
          </div>
          <div className="buttons flex flex-col px-4 lg:flex-row lg:w-6/12 lg:mt-16">
            <Button
              classes="mb-8 lg:mg-2 w-100 lg:mr-8 lg:mb-2 lg:w-64"
              outline
              click={() => scrollTo(SERVICES_TARGET)}
            >
              View Services
            </Button>
            <Button
              classes="mb-2 w-100 lg:w-64"
              variant="secondary"
              click={() => scrollTo(CONTACT_TARGET)}
            >
              <a className={`block`} href={`mailto:${BRAND.email}`}>
                Contact Us
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
