import { WithClasses } from '../../../types/props-with-children-and-classes'
import data from './data.json'
import { ImagesData } from '../models/ImagesData.interface'
import { ComponentWithGallery } from '../models/component-with-gallery.type'
import React, { useMemo } from 'react'
import { MainProjectInfo } from '../MainProjectInfo'
import { ImageWrapper } from '../ImageWrapper'
import { getImagesToDisplay, imageSrc } from '../utils'

export type TokGalleryProps = WithClasses<ComponentWithGallery & {}>

export const TokGallery: React.VFC<TokGalleryProps> = (props) => {
  const { images, baseRef } = data as ImagesData
  const { classes, openModal } = props
  const visibleImages = useMemo(() => getImagesToDisplay(images), [])

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 max-w-screen-2xl m-auto">
        {[...visibleImages.slice(0, 1), null, ...visibleImages.slice(1)].map((imageToDisplay) => {
          if (null === imageToDisplay) {
            return (
              <MainProjectInfo
                classes={`-order-1 xl:order-1`}
                key={'XXX'}
                onClick={() => openModal(0, images, baseRef)}
                span={1}
                header={`Intake and Service Tracking Tool`}
                description={`Track demographic and service data along with supporting documentation, pertaining to your clients`}
              />
            )
          } else {
            const { index, image } = imageToDisplay
            return (
              <ImageWrapper
                classes={`order-1`}
                key={index}
                src={imageSrc(baseRef, image.name)}
                click={(src) => openModal(index, images, baseRef)}
              />
            )
          }
        })}
      </div>
    </>
  )
}
