import React from 'react'
import usePageTarget from '../../contexts/currentTargetContext'
import { CONTACT_TARGET } from '../../static-data/menu-items'
import { Button } from '../Button'
import { Section } from '../Section'
import { BRAND } from '../../static-data/brand'

export interface ContactBannerProps {}

export const ContactBanner: React.VFC<ContactBannerProps> = () => {
  const { scrollTo } = usePageTarget()
  return (
    <div className="bg-primary py-10">
      <Section classes="bg-primary">
        <div className="r-container m-auto px-4">
          <div className="flex flex-col mx-4 md:flex-row md:justify-between md:items-center lg:mx-0">
            <h2 className="font-secondary text-center text-2xl text-white md:text-left mb-4 md:my-0">
              Looking for a First-Class Business Consultant?
            </h2>
            <Button
              variant="secondary"
              classes="w-100 md:w-60 lg:w-64"
              click={() => scrollTo(CONTACT_TARGET)}
            >
              <a className={`block`} href={`mailto:${BRAND.email}`}>
                Contact Us
              </a>
            </Button>
          </div>
        </div>
      </Section>
    </div>
  )
}
