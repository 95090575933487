import React, { useMemo, VFC } from 'react'
import { WithClasses } from '../../../types/props-with-children-and-classes'
import { MainProjectInfo } from '../MainProjectInfo'
import data from './data.json'
import { getImagesToDisplay, imageSrc } from '../utils'
import { ImagesData } from '../models/ImagesData.interface'
import { ComponentWithGallery } from '../models/component-with-gallery.type'
import { OurProjectsPreviewModalProps } from '../../OurProjectsPreviewModal'
import { ImageWrapper } from '../ImageWrapper'

export type SlpMobileGalleryProps = WithClasses<ComponentWithGallery & {}>

const modalProps: Partial<OurProjectsPreviewModalProps> = {
  // imageStyle: {
  //   height: '100%',
  //   maxHeight: '65vh',
  //   width: 'auto',
  // },
  // imageContainerStyle: {},
}

export const SlpMobileGallery: VFC<SlpMobileGalleryProps> = (props) => {
  const { images, baseRef } = data as ImagesData
  const { classes, openModal } = props
  const visibleImages = useMemo(() => getImagesToDisplay(images), [])

  return (
    <>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 justify-center max-w-screen-2xl m-auto">
        {[...visibleImages.slice(0, 2), null, ...visibleImages.slice(2)].map((imageToDisplay) => {
          if (null === imageToDisplay) {
            return (
              <div className={`py-6 -order-1 xl:order-1 flex items-stretch `}>
                <MainProjectInfo
                  key={'xxx'}
                  onClick={() => openModal(0, images, baseRef)}
                  span={1}
                  header={`App for Free/Reduced Meal Income Eligibility`}
                  description={`Looking at complex government income guidelines and distilling the income bracket of students was tedious and time-consuming!
Welcome to the new Income Eligibility App, in three simple steps the magic happens, and you’ll have instant calculated results.
`}
                />
              </div>
            )
          } else {
            const { index, image } = imageToDisplay
            return (
              <ImageWrapper
                classes={`order-1 bg-transparent hover:bg-transparent`}
                key={index}
                src={imageSrc(baseRef, image.name)}
                click={(src) => openModal(index, images, baseRef)}
              />
            )
          }
        })}
      </div>
    </>
  )
}
