import { WithClAndCh } from '../../types/props-with-children-and-classes'
import React, { MouseEventHandler, useState } from 'react'
import { Backdrop } from '../Backdrop'
import { Icon } from '../Icon'

export type CardProps = WithClAndCh<{
  click?: MouseEventHandler
  backdropActive: boolean
}>
export const Card = ({
  children,
  classes = '',
  backdropActive = true,
  click = () => {},
}: CardProps): JSX.Element => {
  const [showBackdrop, setShowBackdrop] = useState(false)

  return (
    <div
      className={`bg-white overflow-hidden rounded-3xl ${classes}  relative flex`}
      onMouseEnter={() => setShowBackdrop(true)}
      onMouseLeave={() => setShowBackdrop(false)}
      onClick={click}
    >
      <Backdrop classes={`bg-white`} isVisible={showBackdrop && backdropActive}>
        <span className={`text-2xl text-primary`}>
          <Icon name={`search-plus`} />
        </span>
      </Backdrop>
      <div className="h-full w-full flex flex-col justify-evenly">{children}</div>
    </div>
  )
}
