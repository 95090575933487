import React, { MouseEventHandler } from 'react'

export interface ButtonProps {
  variant?: 'primary' | 'secondary'
  outline?: boolean
  classes?: string
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  click?: MouseEventHandler<HTMLButtonElement> | undefined
}

export const Button: React.FC<ButtonProps> = ({
  children,
  outline,
  classes,
  variant = 'primary',
  type = 'button',
  disabled = false,
  click = () => {},
}) => {
  let btnClasses: string = ''
  const primaryClasses =
    'bg-primary border-primary hover:border-primary-100 hover:bg-primary-100 active:border-primary-50 active:bg-primary-50 text-white'
  const primaryOutlineClasses =
    'bg-transparent text-primary border-primary hover:border-primary-100 hover:text-primary-100 active:border-primary-50 active:text-primary-50'
  const secondaryClasses =
    'bg-secondary border-secondary hover:bg-gray-50 active:border-gray-50 active:bg-gray-50'
  const secondaryOutlineClasses = ''

  if (variant === 'primary') {
    btnClasses = outline ? primaryOutlineClasses : primaryClasses
  }
  if (variant === 'secondary') {
    btnClasses = secondaryClasses
  }

  return (
    <button
      disabled={disabled}
      type={type}
      className={`font-noto font-bold bg-gray  px-6 py-3 border-2 border-solid border-width-2 rounded-l-full rounded-r-full ${btnClasses} ${classes} disabled:opacity-20 disabled:pointer-events-none`}
      onClick={click}
    >
      {children}
    </button>
  )
}
