import { BRAND } from './brand'

const { name, website } = BRAND

const seo = {
  title: name,
  name: name,
  url: website,
  image: '/page.png',
  robots: 'index,follow',
  type: 'website',
  description: '',
}

export default seo
