import React from 'react'
import { Icon } from '../Icon'
import { BRAND } from '../../static-data/brand'

export interface BrandEmailProps {
  classes?: string
}

export const BrandEmail: React.VFC<BrandEmailProps> = ({ classes = '' }) => {
  const { email } = BRAND
  return (
    <a href={`mailto:${email}`} className={`mx-2 ${classes}`}>
      <Icon name="envelop" /> {email}{' '}
    </a>
  )
}
