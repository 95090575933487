import React, { VFC } from 'react'
import { TestimonialModel } from '../../types/testimonial.model'
import { WithClAndCh } from '../../types/props-with-children-and-classes'
import { Icon } from '../Icon'

export type TestimonialProps = WithClAndCh<{
  testimonial: TestimonialModel
}>

export const Testimonial: VFC<TestimonialProps> = ({ testimonial, classes }) => {
  return (
    <blockquote
      className={`${classes} h-full flex  bg-bg-color  border-l-8 border-primary relative px-6 py-12 xl:p-12 pl-4`}
    >
      <div className="flex"></div>
      <div className="flex flex-col">
        <div className="w-20 mb-4 self-center md:self-start">
          <Icon name={'quote-right'} classes={`text-primary text-5xl opacity-50`} />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex-1 italic text-lg text-justify">{testimonial.content}</div>
          <div className="flex flex-col  lg:flex-row items-center items-start mt-8">
            <div className="flex mb-4 lg:mb-0">
              <img src={testimonial.logo} alt="" className="w-auto h-10  mr-4" />
            </div>
            <div className="flex-col">
              <div className="font-semibold text-xl">{testimonial.author.name}</div>
              <div className="">{testimonial.author.jobPosition}</div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="absolute bottom-6 left-0 right-0 flex justify-center">*/}
      {/*  <Icon name={'quote-left'} classes={`text-primary text-5xl opacity-50`} />*/}
      {/*</div>*/}
    </blockquote>
  )
}
