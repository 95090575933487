import React, { MutableRefObject } from 'react'

const SWIPE_LEFT = 'swipeleft'

export interface PageWrapperProps {
  pageRef: MutableRefObject<HTMLDivElement | undefined>
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, pageRef }) => {
  return (
    <div ref={pageRef as any} className="">
      {children}
    </div>
  )
}
