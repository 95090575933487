import React from 'react'
import { Icon } from '../Icon'
import { Backdrop } from '../Backdrop'
import { NavList } from '../NavList'
import useMenuState from '../../state/menu'

export interface MobileMenuProps {}

export const MobileMenu = (): JSX.Element => {
  const [menuState, setMenuState] = useMenuState()
  const toggleMenu = () => {
    setMenuState({ ...menuState, isOpen: !menuState.isOpen })
  }
  const close = () => {
    console.log('Close click')
    return setMenuState({ ...menuState, isOpen: false })
  }
  return (
    <>
      {menuState.isOpen && (
        <Backdrop light={false} classes="opacity-70 z-10" backdropClick={() => close()} />
      )}
      {menuState.isOpen && (
        <div className="flex flex-col justify-center items-center  fixed inset-0 z-10">
          <NavList linkClicked={() => close()} />
        </div>
      )}
      <div
        id="backdrop-button-holder"
        className={`top-16 right-4 color-white lg:hidden ${
          menuState.isOpen ? 'fixed' : 'absolute'
        } z-10`}
      >
        <span className={`opacity-80 text-2xl text-white`} onClick={() => toggleMenu()}>
          <Icon name={menuState.isOpen ? 'close' : 'menu'} classes="text-white" />
        </span>
      </div>
    </>
  )
}
