import { NavbarMenuItem } from '../types/navbar-menu-item'

const hashed = (target: string) => `#${target}`
export const HOME_TARGET = `home`
export const SERVICES_TARGET = `services`
export const TESTIMONIALS_TARGET = `testimonials`
export const CONTACT_TARGET = `contact`
export const HOW_WE_WORK = `howWeWork`
export const OUR_PROJECTS = `ourProjects`

export const MENU_ITEMS: NavbarMenuItem[] = [
  { label: 'Home', href: hashed(HOME_TARGET) },
  { label: 'Services', href: hashed(SERVICES_TARGET) },
  { label: 'Our Projects', href: hashed(OUR_PROJECTS) },
  { label: 'How we work', href: hashed(HOW_WE_WORK) },
]
