export const testimonials = [
  {
    content: `
    Our product management team felt empowered to make continued progress with the information Shula shared. Her years of experience in the product development world made her course very relatable for our team. The delivery was clear and simple yet covered crucial industry knowledge. We were lucky to have this opportunity and wish it upon others too!
    `,
    logo: '/images/testimonials/chorus-logo.png',
    author: {
      name: 'A. Soroka',
      jobPosition: 'COO Chorus Cloud Solutions',
    },
  },
  {
    content:
      'Shula has streamlined my company in so many ways! The professional website she created is designed to perfection and has brought me many new customers and business leads. My long-time dream of a custom app finally came to reality. The finished product wildly exceeded my vision and expectations. We were afforded so much attention, time, and thought making me feel like I was their only customer. Anyone looking to upgrade their business or begin a custom project should definitely reach out to SFT Consulting.',
    logo: '/images/testimonials/slp-logo.png',
    author: {
      name: 'Chava Shemesh',
      jobPosition: 'Food Service Director',
    },
  },
  {
    content:
      'SFT Consulting Services, brings tremendous value, expertise and insight to each project they embark on. Shula and her team have been a pleasure to work with! They were determined to thoroughly understand all aspects of our business, and built our product with much foresight. The end product allowed us to be more effective, streamline internal operations and surpass our current needs. Our company has become more efficient through the system they built. We are so thankful for her help!',
    logo: '/images/testimonials/tok-logo1.png',
    author: { name: 'M. Behar', jobPosition: 'CFO Tree of Knowledge' },
  },
  {
    content:
      'Working with Shula was truly a great experience. Her service was unparalleled in every way! Her professionalism, responsiveness, timely delivery and hands-on collaboration coupled with her creativity and ease have made this engagement a very successful one!\n',
    logo: '/images/testimonials/tok-logo1.png',
    author: { name: 'S. Jaffa', jobPosition: 'Product Manager, Tree of Knowledge' },
  },
]
