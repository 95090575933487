import React from 'react'
import { WithClAndCh } from '../../types/props-with-children-and-classes'

export type BackdropProps = WithClAndCh & {
  backdropClick?: Function
  isVisible?: boolean
  light?: boolean
}

export const Backdrop = ({
  classes = '',
  backdropClick,
  children,
  isVisible,
  light = true,
}: BackdropProps): JSX.Element => {
  return (
    isVisible ? (
      <div
        className={`absolut ${
          light ? 'bg-white' : 'bg-black'
        } opacity-30 inset-0 absolute ${classes} flex justify-center items-center`}
        style={{ backdropFilter: 'blur(5px)' }}
        onClick={() => backdropClick && backdropClick()}
      >
        {children}
      </div>
    ) : (
      <></>
    )
  ) as JSX.Element
}
