import React from 'react'

export interface LogoInlineProps {}

export const LogoInline: React.VFC<LogoInlineProps> = ({}) => {
  return (
    <svg
      id="_ÎÓÈ_1"
      data-name="—ÎÓÈ_1"
      xmlns="http://www.w3.org/2000/svg"
      width="661.84"
      height="225.87"
      viewBox="0 0 661.84 225.87"
      className="max-h-full w-auto"
    >
      <defs>
        <style>
          {`.cls-1 {fill: #fff;}`}
          {`.cls-2 {fill: var(--color-primary);}`}
        </style>
      </defs>
      <g>
        <path d="M834.23,505.67c0,.22,0,.44,0,.66" transform="translate(-172.86 -369.69)" />
        <path d="M834.7,543.67v.5" transform="translate(-172.86 -369.69)" />
        <g>
          <path
            className="cls-1"
            d="M704,452.25l-75-1v3.1s4,1,5,3.11,1,86.18.13,94.64c-2.48,3.21-7.13,4.82-7.13,4.82V560h28v-3.11a22.89,22.89,0,0,1-3.17-1.51,23.66,23.66,0,0,1-4.83-3.67q.5-25.9,1-51.8c8.51.13,17,.2,25.53.66,3.16.17,8.44-.53,10.91,1.77,2.11,2,2.56,5.14,2.56,7.93h4V487.48h-4c.45,0-1.23,5.17-1.47,5.56-1.37,2.13-3.38,2.68-5.74,2.67l-17.28-.07-8.64,0c-1.36,0-2.75.06-4.11,0-1.9-.1-1.74-.06-1.76-2.09-.14-12,0-24.05,0-36.07,0,0,40-2.07,45,0s11,8.29,11,8.29h3Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-1"
            d="M615.62,515a30.59,30.59,0,0,0-10-9.83q-6.66-4.24-22.11-10.93-20.28-8.7-25.2-13.06t-4.92-9.62q0-6.85,7-12t18.05-5.17a31.47,31.47,0,0,1,25.74,12.38q2.17,4.72,4.34,9.45a2.15,2.15,0,0,0,1.93,1.22,2.19,2.19,0,0,0,2.1-2.64l-3.21-18.14a43.75,43.75,0,0,0-10.72-4.77,68.13,68.13,0,0,0-17-1.86q-17.86,0-28.48,7.8t-10.62,19a19.8,19.8,0,0,0,3,10.91q4.05,6.06,12.16,11.47,5,3.32,21.63,10.55t22.11,12.64q5.51,5.42,5.5,12,0,4.77-3.62,9.82a22.7,22.7,0,0,1-10,7.72,36.4,36.4,0,0,1-14,2.66,34.85,34.85,0,0,1-22.3-7.84c-5.23-4.16-9.65-14.42-11-17.77-.22-.53-.93-2.36-2.13-2.51a1.72,1.72,0,0,0-1.45.57,1.82,1.82,0,0,0-.34,1.17c0,2.74,0,23.35,0,23.35A70.74,70.74,0,0,0,574.58,560c1.17,0,13.76,0,24.63-4.84A34.34,34.34,0,0,0,610.06,548a32.56,32.56,0,0,0,3.58-4.26A27.73,27.73,0,0,0,619,527.46,23.59,23.59,0,0,0,615.62,515Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-1"
            d="M814.17,451.22H722l-6,19.68h3a21.75,21.75,0,0,1,5-8.29c4-4,10-5.11,22-7.25a80.08,80.08,0,0,1,16-1c-1,0,2,92.21,0,96.36s-8,6.21-8,6.21V560h28v-3.11s-6-2.07-8-6.21,1-96.36,0-96.36h0l.16,0,0,0a80.08,80.08,0,0,1,16,1c12,2.14,18,3.21,22,7.25a21.35,21.35,0,0,1,4.67,7.39c.06.32.12.62.16.9h3.17Z"
            transform="translate(-172.86 -369.69)"
          />
        </g>
        <g>
          <path
            className="cls-2"
            d="M371,496.41c8.94,2.61,14.5,6.3,14.58,10.5.17,8.52-22.21,15.85-50,16.39s-50.4-5.92-50.57-14.43c-.12-6.59,18.5-11.68,18.31-12.31-.27-.87-36.42,7.72-36.29,18.09S303,535.12,334.44,535c32.58-.16,68.79-11.42,68.59-21.9C402.89,505.83,390.13,499.69,371,496.41Z"
            transform="translate(-172.86 -369.69)"
          />
          <g>
            <path
              className="cls-2"
              d="M333.28,414a9.42,9.42,0,0,0-3.34.61,7.37,7.37,0,0,1,.6,2.93c0,3.55-2.39,6.42-5.35,6.42a4.61,4.61,0,0,1-2.5-.75,15.46,15.46,0,0,0-.49,3.83c0,7.21,5,13,11.08,13s11.08-5.84,11.08-13S339.4,414,333.28,414Z"
              transform="translate(-172.86 -369.69)"
            />
            <path className="cls-2" d="M363,387" transform="translate(-172.86 -369.69)" />
            <path
              className="cls-2"
              d="M334.46,385a6.21,6.21,0,0,1,2.19.4,4.81,4.81,0,0,0-.39,1.92c0,2.32,1.57,4.2,3.5,4.2a3.06,3.06,0,0,0,1.64-.49,9.87,9.87,0,0,1,.32,2.51c0,4.72-3.25,8.55-7.26,8.55s-7.26-3.83-7.26-8.55S330.45,385,334.46,385Z"
              transform="translate(-172.86 -369.69)"
            />
            <path
              className="cls-2"
              d="M335.58,369.8a3.76,3.76,0,0,0-1.33-.09,3,3,0,0,1-.05,1.18,2.34,2.34,0,0,1-2.64,1.93,1.83,1.83,0,0,1-.88-.52,5.83,5.83,0,0,0-.55,1.41c-.68,2.74.65,5.43,3,6s4.76-1.17,5.45-3.91S337.9,370.38,335.58,369.8Z"
              transform="translate(-172.86 -369.69)"
            />
          </g>
          <path
            className="cls-2"
            d="M348.81,478.72c-2.62-2.16-5.87-3.47-8-6.32-2.33-3.13-3.11-7.93-5.9-10.5-2.4,3.16-3.72,7.21-6.15,10.29-2.28,2.89-5.6,4.09-8.2,6.53-1.07,1-8,7.54-7.08,16.42.8,7.76,7.09,12.42,9.91,14.19.53.33,1.11.66,1.11.66a21.91,21.91,0,0,0,10.15,3,21.23,21.23,0,0,0,10.23-3s.58-.33,1.1-.66a21.27,21.27,0,0,0,9.91-14.19C356.7,488.3,352.75,482,348.81,478.72Zm-19.7,5.58a10.44,10.44,0,0,0-5.76,7.13,10,10,0,0,0,3.83,9.69,1.65,1.65,0,0,1,.36,2.17,1.34,1.34,0,0,1-2,.4,13,13,0,0,1,2.49-22.29,1.37,1.37,0,0,1,1.84.87A1.63,1.63,0,0,1,329.11,484.3Z"
            transform="translate(-172.86 -369.69)"
          />
          <g>
            <path
              className="cls-2"
              d="M217,504.8c0,13.39,28,24.91,68.21,30-1.19-.33-2.34-.68-3.44-1-31.08-5.14-52.09-15-52.09-26.24,0-14.21,33.36-26.11,78.21-29.26l4.43-5.8C258.22,475.07,217,488.56,217,504.8Z"
              transform="translate(-172.86 -369.69)"
            />
            <path
              className="cls-2"
              d="M356,472.75l4.54,5.94c42.08,3.69,72.79,15.2,72.79,28.83,0,11.51-21.92,21.51-54.1,26.57-1.05.32-2.13.63-3.27.94,41.16-5,70.05-16.64,70.05-30.23C446,489.09,407.46,476,356,472.75Z"
              transform="translate(-172.86 -369.69)"
            />
          </g>
          <path
            className="cls-2"
            d="M448.24,473.34c21,8.12,33.57,18.58,33.63,30.05.14,26.37-66,48.09-147.82,48.52s-148.18-20.6-148.32-47c-.08-14.45,19.77-27.5,51.19-36.42-39,9.56-64.14,24.64-64.06,41.5C173,538.36,244.57,561,332.7,560.5S492.15,536.69,492,508.35C491.93,494.7,475.3,482.39,448.24,473.34Z"
            transform="translate(-172.86 -369.69)"
          />
          <path className="cls-2" d="M234,469" transform="translate(-172.86 -369.69)" />
        </g>
        <g>
          <path
            className="cls-2"
            d="M555.49,593.27a.15.15,0,0,1,0,.17,6.19,6.19,0,0,1-2.42,1.4,10.25,10.25,0,0,1-3.34.47A8.6,8.6,0,0,1,547,595a5.33,5.33,0,0,1-2-1.3,6.29,6.29,0,0,1-1.6-4.73,7.6,7.6,0,0,1,.92-3.55,6.37,6.37,0,0,1,2.25-2.41,9.21,9.21,0,0,1,5.07-1.28,19.59,19.59,0,0,1,2.12.1,4.78,4.78,0,0,1,1.42.28s0,0,0,.1,0,.38-.09.82-.07.67-.1.94c0,.08,0,.11-.08.11a.09.09,0,0,1-.1-.08,1.38,1.38,0,0,0-.11-.28A2.93,2.93,0,0,0,554,583a7,7,0,0,0-3.06-.52,5.51,5.51,0,0,0-4.14,1.59,6.26,6.26,0,0,0-1.74,4.52,6,6,0,0,0,1.84,4.66,5.14,5.14,0,0,0,1.87,1.16,7.83,7.83,0,0,0,2.29.27,4.84,4.84,0,0,0,2.06-.47,3.35,3.35,0,0,0,1.48-1.21,2.27,2.27,0,0,0,.33-.82c0-.11,0-.17.06-.17s0,0,.07.09a2.69,2.69,0,0,0,.22.62C555.29,592.89,555.38,593.06,555.49,593.27Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M570.33,588.27a7.21,7.21,0,0,1-1.49,4.83,5.31,5.31,0,0,1-2.71,1.87,6.9,6.9,0,0,1-2.38.34,8.07,8.07,0,0,1-3.52-.59,4.7,4.7,0,0,1-1.44-1.11,4.82,4.82,0,0,1-1-1.8,10.6,10.6,0,0,1-.38-3.12,8.54,8.54,0,0,1,.36-2.5,6.27,6.27,0,0,1,1.28-2.35,6.18,6.18,0,0,1,2.44-1.58,6.66,6.66,0,0,1,2.5-.44,7.86,7.86,0,0,1,3.19.5,4.69,4.69,0,0,1,2,1.52A7.36,7.36,0,0,1,570.33,588.27Zm-1.54.53a8.23,8.23,0,0,0-.33-2.46,5.53,5.53,0,0,0-1.15-2.12,4.6,4.6,0,0,0-1.72-1.24,5.93,5.93,0,0,0-2.3-.48,3.75,3.75,0,0,0-2.16.48,4.62,4.62,0,0,0-1.66,1.89,8.17,8.17,0,0,0-.61,3.34,8.28,8.28,0,0,0,1.09,4.58,4.51,4.51,0,0,0,2,1.63,5.35,5.35,0,0,0,2.24.47,4.47,4.47,0,0,0,2.52-.76,4.54,4.54,0,0,0,1.54-2A8.31,8.31,0,0,0,568.79,588.8Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M585.91,582.32c0,.07,0,.11-.1.11h-1.36c-.13,0-.2.09-.2.27s0,.94,0,2.66,0,2.65,0,2.79,0,1.3,0,3.49,0,3.34,0,3.46c0,.31,0,.46-.12.46a.26.26,0,0,1-.22-.12q-.88-1.19-5.26-6.77Q577,586.6,574.87,584c-.09-.07-.15,0-.18.08s-.07,1.28-.15,3.59-.11,3.79-.11,4.38c0,1.73.18,2.6.52,2.6h1.3c.07,0,.1,0,.1.08v.36a.07.07,0,0,1-.08.08h-3.84q-.12,0-.12-.06v-.36c0-.07,0-.1.12-.1h1.2c.11,0,.16,0,.16-.15V593.1c0-.39.05-2,.14-4.68s.14-4.13.14-4.18c0-1.2-.41-1.81-1.24-1.81h-.68s-.08,0-.08-.09V582c0-.06,0-.09.1-.09h3.08a.25.25,0,0,1,.22.15c.06.11.6.84,1.62,2.2s1.59,2.13,1.72,2.28c1.46,1.94,3,4,4.64,6a.11.11,0,0,0,.08,0s.07-.09.08-.27,0-1.28,0-3.46,0-3.37,0-3.55c-.09-1.92-.33-2.88-.7-2.88h-1.34q-.12,0-.12-.09V582c0-.06,0-.09.1-.09h4.26c.08,0,.12,0,.12.07Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M595.33,591.53a3.63,3.63,0,0,1-1,2.67,4.34,4.34,0,0,1-3.2,1.09,5.27,5.27,0,0,1-3.48-.89.6.6,0,0,1-.11-.4s0-.43,0-1.15,0-1.1,0-1.15a.12.12,0,0,1,.13-.13h.4a.13.13,0,0,1,.14.13c0,.09,0,.29.06.62A2.41,2.41,0,0,0,589,594a2.65,2.65,0,0,0,1,.53,3.41,3.41,0,0,0,1.14.22,2.65,2.65,0,0,0,2.61-1.3A3.57,3.57,0,0,0,594,592a1.9,1.9,0,0,0-.56-1.37,5.74,5.74,0,0,0-2.17-1.2,8.24,8.24,0,0,1-2.5-1.26,3.21,3.21,0,0,1-1-2.6,3.53,3.53,0,0,1,1.35-2.8,4,4,0,0,1,2.69-.88,5.49,5.49,0,0,1,2,.37,2.76,2.76,0,0,1,.89.49.35.35,0,0,1,.16.26,14.71,14.71,0,0,1,.11,2q0,.21-.06.21h-.47s-.08,0-.1-.12-.08-.44-.14-.74a1.37,1.37,0,0,0-.68-1,4.49,4.49,0,0,0-2.28-.57,2.51,2.51,0,0,0-1.71.5,2.38,2.38,0,0,0-.59,1.83,2,2,0,0,0,.55,1.4,3.06,3.06,0,0,0,1,.62l1.27.52c.84.36,1.34.59,1.49.67a4.81,4.81,0,0,1,1.24,1A3.29,3.29,0,0,1,595.33,591.53Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M610.91,582.32c0,.07,0,.11-.06.11h-.56c-.5,0-.78,0-.84,0a1.91,1.91,0,0,0,0,.43c0,.42,0,1.07-.06,2,0,1.25,0,2.93,0,5q.09,5.63-4.63,5.63a10.39,10.39,0,0,1-3.31-.44,3.07,3.07,0,0,1-1.83-1.54,6.89,6.89,0,0,1-.52-2.77c0-.8,0-2.8,0-6,0-1.53-.12-2.29-.38-2.29h-1.32c-.07,0-.1,0-.1-.09V582c0-.08,0-.11.1-.11h4.8c.08,0,.12,0,.12.07v.31c0,.09,0,.13-.08.13h-1.36c-.08,0-.12.06-.14.17q0,.4-.05,6.43a22.36,22.36,0,0,0,.11,2.85,3,3,0,0,0,1.3,2.12,5.19,5.19,0,0,0,2.92.72,3.94,3.94,0,0,0,2.94-1.11c.56-.56.84-1.66.84-3.32,0-2.18,0-3.8-.06-4.85-.08-2-.28-3-.62-3h-1.26c-.05,0-.09,0-.1,0a.14.14,0,0,1,0-.06V582q0-.09.12-.09h4c.07,0,.1,0,.1.09Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M623,593.82a2.82,2.82,0,0,1-.32.62,4.22,4.22,0,0,1-.34.64.21.21,0,0,1-.16.12h-9.3c-.08,0-.12,0-.12-.1v-.32c0-.07,0-.1.1-.1h1.46q.18,0,.18-.21c0-.05,0-.45,0-1.18s0-2.34,0-4.84,0-3.81,0-3.94c0-1.38-.13-2.08-.38-2.08H613c-.09,0-.13,0-.13-.11V582c0-.08,0-.11.12-.11h5.28c.06,0,.1,0,.1.07v.35c0,.06,0,.09-.08.09h-2a.17.17,0,0,0-.18.14c0,.09,0,1.68-.1,4.77,0,2.9-.06,4.55-.06,5,0,1.5.18,2.25.52,2.25,2.1,0,3.31,0,3.62,0a5.43,5.43,0,0,0,2.45-.69,1.06,1.06,0,0,1,.29-.15A.22.22,0,0,1,623,593.82Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M635.09,582.05a13.73,13.73,0,0,1-.3,1.43c0,.09-.07.13-.12.13s-.13-.05-.19-.15a1.12,1.12,0,0,0-.78-.57,19,19,0,0,0-3.47-.21c-.11,0-.16,0-.17.12s0,1.61-.06,4.55-.06,4.35-.06,4.55c0,1.85.16,2.78.48,2.78h1.68s.07,0,.07.08v.34c0,.06,0,.1-.07.1h-5.37a.1.1,0,0,1-.11-.1v-.32c0-.07,0-.1.11-.1h1.57c.13,0,.19-.09.19-.25,0-1.37,0-2.12,0-2.25s0-1.21,0-3.3,0-3.17,0-3.26c0-2-.12-2.94-.34-2.94a15.31,15.31,0,0,0-3.56.28,1.6,1.6,0,0,0-1,.54c-.07.11-.13.17-.19.17s-.13,0-.13-.07a.56.56,0,0,1,.07-.26c.18-.66.33-1.14.44-1.46,0-.09.1-.12.21-.1s.81.06,2,.12c1.4.06,2.54.09,3.4.09,2.79,0,4.37,0,4.75,0a3.6,3.6,0,0,0,.49,0l.22,0C635.05,581.9,635.09,582,635.09,582.05Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M641.48,595.12s0,.08-.06.08h-4.73c-.06,0-.1,0-.11,0a.19.19,0,0,1,0-.07v-.3c0-.06,0-.1,0-.11H638c.09,0,.13-.1.13-.28l0-10.1c0-1.23-.11-1.85-.34-1.85h-1.24c-.09,0-.14,0-.14-.1V582s0-.07.1-.07h4.74c.06,0,.09,0,.09.07v.36c0,.06,0,.08-.11.08h-1.38c-.12,0-.17.07-.17.21,0,5.91,0,9,0,9.16,0,1,0,1.51,0,1.65,0,.81.17,1.21.39,1.21h1.37c.06,0,.08,0,.08.08Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M657,582.32c0,.07,0,.11-.09.11h-1.36q-.21,0-.21.27s0,.94,0,2.66,0,2.65,0,2.79,0,1.3,0,3.49,0,3.34,0,3.46c0,.31,0,.46-.12.46a.25.25,0,0,1-.22-.12q-.9-1.19-5.27-6.77Q648.08,586.6,646,584c-.09-.07-.15,0-.19.08s-.06,1.28-.14,3.59-.11,3.79-.11,4.38c0,1.73.17,2.6.52,2.6h1.3c.06,0,.09,0,.09.08v.36s0,.08-.07.08h-3.84c-.09,0-.13,0-.13-.06v-.36c0-.07,0-.1.13-.1h1.2a.14.14,0,0,0,.16-.15V593.1c0-.39,0-2,.14-4.68s.14-4.13.14-4.18c0-1.2-.42-1.81-1.24-1.81h-.69s-.07,0-.07-.09V582a.08.08,0,0,1,.09-.09h3.09a.24.24,0,0,1,.21.15c.06.11.6.84,1.62,2.2s1.59,2.13,1.72,2.28c1.46,1.94,3,4,4.64,6l.08,0s.08-.09.09-.27,0-1.28,0-3.46,0-3.37,0-3.55c-.1-1.92-.33-2.88-.7-2.88h-1.34c-.09,0-.13,0-.13-.09V582c0-.06,0-.09.11-.09h4.26c.08,0,.11,0,.11.07Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M671.42,589.45c0,.09,0,.13-.11.13h-1.4a.11.11,0,0,0-.12.12c0-.05,0,.15,0,.62s0,.92,0,1.36c0,.81,0,1.55.07,2.24,0,.09,0,.14-.1.15a4.77,4.77,0,0,0-1.44.51,8.57,8.57,0,0,1-3.55.75,9.31,9.31,0,0,1-3-.33,5.38,5.38,0,0,1-2.5-1.92,7,7,0,0,1-1-4,7.08,7.08,0,0,1,1.64-4.56,7.22,7.22,0,0,1,3.56-2.17,11.51,11.51,0,0,1,3.36-.4,10.24,10.24,0,0,1,3.16.36.14.14,0,0,1,.12.13s0,.18-.05.44-.06.47-.07.51c-.07.66-.11,1.06-.14,1.17s0,.11-.1.11-.14,0-.14-.09a1.82,1.82,0,0,0-.06-.46,1.15,1.15,0,0,0-.52-.65,8.15,8.15,0,0,0-5.55-.45,5.2,5.2,0,0,0-2.07,1.43,6,6,0,0,0-1.56,4.27,6.12,6.12,0,0,0,1.66,4.52,4.76,4.76,0,0,0,2.15,1.29,8.24,8.24,0,0,0,2.15.22,4.17,4.17,0,0,0,2.33-.76.33.33,0,0,0,.09-.18v-1.73c0-.62,0-1,0-1.3,0-.78-.11-1.17-.2-1.17h-2.1q-.12,0-.12-.18v-.2c0-.1,0-.15.14-.15h5.4a.12.12,0,0,1,.13.13Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M685.7,591.53a3.63,3.63,0,0,1-1,2.67,4.38,4.38,0,0,1-3.21,1.09,5.22,5.22,0,0,1-3.47-.89.55.55,0,0,1-.12-.4s0-.43,0-1.15,0-1.1,0-1.15a.12.12,0,0,1,.14-.13h.4c.08,0,.12,0,.13.13a5.14,5.14,0,0,1,.06.62,2.41,2.41,0,0,0,.78,1.71,2.73,2.73,0,0,0,1,.53,3.41,3.41,0,0,0,1.14.22,2.64,2.64,0,0,0,2.61-1.3,3.42,3.42,0,0,0,.29-1.51,1.89,1.89,0,0,0-.55-1.37,5.88,5.88,0,0,0-2.18-1.2,8.37,8.37,0,0,1-2.5-1.26,3.21,3.21,0,0,1-1-2.6,3.53,3.53,0,0,1,1.35-2.8,4,4,0,0,1,2.7-.88,5.57,5.57,0,0,1,2,.37,2.87,2.87,0,0,1,.89.49.34.34,0,0,1,.15.26,14.71,14.71,0,0,1,.11,2q0,.21-.06.21h-.47s-.08,0-.1-.12-.08-.44-.13-.74a1.41,1.41,0,0,0-.69-1,4.42,4.42,0,0,0-2.27-.57,2.52,2.52,0,0,0-1.72.5,2.38,2.38,0,0,0-.59,1.83,2,2,0,0,0,.55,1.4,3.25,3.25,0,0,0,1,.62l1.27.52c.83.36,1.33.59,1.48.67a4.64,4.64,0,0,1,1.24,1A3.29,3.29,0,0,1,685.7,591.53Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M698,593.8c-.18.37-.4.8-.65,1.28a.25.25,0,0,1-.21.12h-9.24q-.09,0-.09-.12v-.3c0-.07,0-.1.07-.1h1.45c.13,0,.19-.07.19-.21s0-1.06,0-3,0-2.94,0-3,0-.73,0-2,0-2,0-2c0-1.33-.13-2-.38-2h-1.19a.08.08,0,0,1-.09-.09V582c0-.06,0-.09.11-.09h8.74l.48,0,.29,0q.12,0,.09.09c-.09.36-.2.78-.34,1.24-.05.15-.1.23-.16.23l-.13-.15a2.24,2.24,0,0,0-1.14-.5,37.14,37.14,0,0,0-4.64-.23.16.16,0,0,0-.16.12c0,.08-.05,1.69-.05,4.84,0,.13.06.2.17.2h4.77a.15.15,0,0,0,.16-.15c0-.1.05-.31.08-.63s.06-.49.07-.52,0-.19.11-.19h.15c.08,0,.12.06.12.17s0,.3,0,.81,0,.83,0,.93,0,.44,0,1,0,.9,0,.94,0,.21-.11.21h-.16c-.06,0-.1,0-.11-.13,0-.42-.06-.91-.1-1.49,0-.13-.08-.19-.19-.19l-2.4,0-2.37,0c-.09,0-.13.06-.13.18v3.68q0,2.34.51,2.34,4.57,0,6.07-.7l.44-.25C698,593.67,698.05,593.72,698,593.8Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M712.25,595.08c0,.08,0,.12-.08.12H709a.39.39,0,0,1-.24-.12,14,14,0,0,1-1.37-1.64l-1-1.43q-.58-.87-1.62-2.73c-.05-.11-.1-.17-.16-.17-.31,0-.85,0-1.62-.11-.11,0-.16,0-.16.09s0,.54,0,1.24,0,1.44,0,2.21c0,1.43.15,2.14.44,2.14H705a.09.09,0,0,1,.1.1v.32c0,.06,0,.1-.08.1h-5.18c-.06,0-.09,0-.1,0a.1.1,0,0,1,0-.07v-.3c0-.06,0-.1,0-.11h1.35c.11,0,.16-.1.16-.3v-9.26c0-1.79-.13-2.69-.34-2.69h-1.24c-.07,0-.1,0-.1-.08V582s0-.07.08-.07h2.92l1.12,0,1.09,0a7.16,7.16,0,0,1,1.24.1,3.85,3.85,0,0,1,2.17,1,3,3,0,0,1,1,2.3,3.64,3.64,0,0,1-.94,2.59,4.49,4.49,0,0,1-1.88,1.07.1.1,0,0,0-.06.12,11,11,0,0,0,2,2.88c.54.61.94,1.05,1.18,1.31a9.36,9.36,0,0,0,1.12,1,2.51,2.51,0,0,0,1.56.46c.1,0,.16,0,.16.08Zm-4.74-9.33a2.83,2.83,0,0,0-.86-2.12,4.91,4.91,0,0,0-3.48-1.13c-.22,0-.34,0-.36.14,0,1.87,0,3,0,3.49v2.23c0,.07.05.12.14.14s.56,0,1.24,0a6.75,6.75,0,0,0,.94-.08l.48-.08A2.43,2.43,0,0,0,707.51,585.75Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M725.45,582.34c0,.06,0,.09-.05.09h-.63a.29.29,0,0,0-.27.15q-1,2.52-2.25,5.58-1.08,2.69-2.75,6.77c-.13.31-.23.46-.31.46s-.17-.11-.26-.33q-2-5-2.75-6.7l-1.08-2.59a21.56,21.56,0,0,0-1.44-3,.79.79,0,0,0-.55-.34h-.33c-.06,0-.1,0-.11,0a.14.14,0,0,1,0-.06V582c0-.06,0-.09.1-.09H717c.07,0,.11,0,.11.07v.33c0,.07,0,.11-.07.11h-1.21c-.16,0-.24.08-.24.25a1.48,1.48,0,0,0,.11.46l1.91,4.63q1.8,4.58,2,4.91c.06.13.11.2.15.2s.09,0,.14-.12.5-1.13,1.13-2.76c.32-.84.64-1.68,1-2.53,0-.1.25-.65.62-1.65l.64-1.75a4.57,4.57,0,0,0,.23-1.26c0-.25-.1-.38-.3-.38h-1.1c-.09,0-.13,0-.13-.09V582c0-.06,0-.09.08-.09h3.34c.06,0,.09,0,.09.07Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M731.78,595.12s0,.08-.06.08H727a.14.14,0,0,1-.11,0,.19.19,0,0,1,0-.07v-.3c0-.06,0-.1,0-.11h1.4c.09,0,.14-.1.14-.28l0-10.1c0-1.23-.12-1.85-.34-1.85h-1.24c-.1,0-.14,0-.14-.1V582s0-.07.1-.07h4.73c.06,0,.09,0,.09.07v.36c0,.06,0,.08-.11.08h-1.37q-.18,0-.18.21,0,8.87,0,9.16c0,1,0,1.51,0,1.65.05.81.18,1.21.39,1.21h1.38a.07.07,0,0,1,.08.08Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M745.5,593.27a.12.12,0,0,1,0,.17,6,6,0,0,1-2.42,1.4,10.2,10.2,0,0,1-3.34.47A8.49,8.49,0,0,1,737,595a5.36,5.36,0,0,1-2-1.3,6.33,6.33,0,0,1-1.6-4.73,7.73,7.73,0,0,1,.92-3.55A6.48,6.48,0,0,1,736.6,583a9.16,9.16,0,0,1,5.07-1.28,19.4,19.4,0,0,1,2.11.1,4.78,4.78,0,0,1,1.42.28.09.09,0,0,1,.06.1c0,.1,0,.38-.1.82s-.07.67-.1.94c0,.08,0,.11-.07.11a.1.1,0,0,1-.11-.08,1.2,1.2,0,0,0-.1-.28A3.25,3.25,0,0,0,744,583a7,7,0,0,0-3.06-.52,5.51,5.51,0,0,0-4.14,1.59,6.26,6.26,0,0,0-1.74,4.52,6,6,0,0,0,1.84,4.66,5.18,5.18,0,0,0,1.88,1.16,7.71,7.71,0,0,0,2.28.27,4.81,4.81,0,0,0,2.06-.47,3.38,3.38,0,0,0,1.49-1.21,2.27,2.27,0,0,0,.33-.82c0-.11,0-.17.06-.17s0,0,.06.09a2.69,2.69,0,0,0,.22.62C745.3,592.89,745.39,593.06,745.5,593.27Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M757.62,593.8c-.18.37-.4.8-.66,1.28a.23.23,0,0,1-.2.12h-9.24c-.06,0-.1,0-.1-.12v-.3c0-.07,0-.1.08-.1H749c.13,0,.19-.07.19-.21s0-1.06,0-3,0-2.94,0-3,0-.73,0-2,0-2,0-2c0-1.33-.12-2-.38-2h-1.18c-.06,0-.1,0-.1-.09V582q0-.09.12-.09h8.74l.47,0,.29,0c.09,0,.12,0,.1.09-.09.36-.21.78-.34,1.24,0,.15-.11.23-.16.23l-.14-.15a2.24,2.24,0,0,0-1.14-.5,37,37,0,0,0-4.64-.23.17.17,0,0,0-.16.12c0,.08,0,1.69,0,4.84,0,.13.05.2.16.2h4.78c.09,0,.14-.05.16-.15s0-.31.08-.63.06-.49.06-.52,0-.19.12-.19H756c.07,0,.11.06.11.17s0,.3,0,.81,0,.83,0,.93,0,.44,0,1,0,.9,0,.94,0,.21-.1.21h-.16c-.06,0-.1,0-.12-.13,0-.42-.06-.91-.09-1.49,0-.13-.09-.19-.19-.19l-2.41,0-2.37,0q-.12,0-.12.18v3.68q0,2.34.51,2.34c3,0,5.07-.23,6.07-.7l.44-.25C757.62,593.67,757.64,593.72,757.62,593.8Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M767.26,591.53a3.63,3.63,0,0,1-1,2.67,4.38,4.38,0,0,1-3.21,1.09,5.22,5.22,0,0,1-3.47-.89.55.55,0,0,1-.12-.4s0-.43,0-1.15,0-1.1,0-1.15a.12.12,0,0,1,.14-.13h.4a.13.13,0,0,1,.13.13,5.14,5.14,0,0,1,.06.62,2.41,2.41,0,0,0,.78,1.71,2.73,2.73,0,0,0,1,.53,3.41,3.41,0,0,0,1.14.22,2.65,2.65,0,0,0,2.61-1.3,3.42,3.42,0,0,0,.29-1.51,1.89,1.89,0,0,0-.55-1.37,5.88,5.88,0,0,0-2.18-1.2,8.37,8.37,0,0,1-2.5-1.26,3.21,3.21,0,0,1-1-2.6,3.53,3.53,0,0,1,1.35-2.8,4,4,0,0,1,2.7-.88,5.57,5.57,0,0,1,2.05.37,2.69,2.69,0,0,1,.88.49.32.32,0,0,1,.16.26,14.71,14.71,0,0,1,.11,2q0,.21-.06.21h-.47s-.08,0-.1-.12-.08-.44-.13-.74a1.41,1.41,0,0,0-.69-1,4.42,4.42,0,0,0-2.27-.57,2.52,2.52,0,0,0-1.72.5,2.38,2.38,0,0,0-.59,1.83,2,2,0,0,0,.55,1.4,3.25,3.25,0,0,0,1,.62l1.26.52c.84.36,1.34.59,1.49.67a4.64,4.64,0,0,1,1.24,1A3.29,3.29,0,0,1,767.26,591.53Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M784.1,593.82a3.06,3.06,0,0,1-.31.62,6,6,0,0,1-.35.64c-.05.08-.1.12-.16.12H774c-.08,0-.12,0-.12-.1v-.32a.09.09,0,0,1,.1-.1h1.47c.11,0,.17-.07.17-.21s0-.45,0-1.18,0-2.34,0-4.84,0-3.81,0-3.94c0-1.38-.13-2.08-.38-2.08h-1.1c-.09,0-.14,0-.14-.11V582c0-.08,0-.11.12-.11h5.28c.07,0,.1,0,.1.07v.35c0,.06,0,.09-.08.09h-2a.17.17,0,0,0-.18.14c0,.09,0,1.68-.1,4.77,0,2.9-.06,4.55-.06,5,0,1.5.18,2.25.52,2.25,2.11,0,3.31,0,3.63,0a5.51,5.51,0,0,0,2.45-.69.94.94,0,0,1,.28-.15A.22.22,0,0,1,784.1,593.82Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M795.29,593.82a2.54,2.54,0,0,1-.31.62,4.22,4.22,0,0,1-.34.64.21.21,0,0,1-.17.12h-9.29c-.08,0-.12,0-.12-.1v-.32c0-.07,0-.1.1-.1h1.46q.18,0,.18-.21c0-.05,0-.45,0-1.18s0-2.34,0-4.84,0-3.81,0-3.94c0-1.38-.13-2.08-.38-2.08h-1.11c-.09,0-.13,0-.13-.11V582a.1.1,0,0,1,.11-.11h5.29c.06,0,.09,0,.09.07v.35c0,.06,0,.09-.07.09h-2a.16.16,0,0,0-.17.14c0,.09-.05,1.68-.1,4.77,0,2.9-.06,4.55-.06,5,0,1.5.17,2.25.52,2.25,2.1,0,3.31,0,3.62,0a5.47,5.47,0,0,0,2.45-.69.93.93,0,0,1,.29-.15A.2.2,0,0,1,795.29,593.82Z"
            transform="translate(-172.86 -369.69)"
          />
          <path
            className="cls-2"
            d="M808.32,593.27a.15.15,0,0,1,0,.17,6.19,6.19,0,0,1-2.42,1.4,10.25,10.25,0,0,1-3.34.47,8.56,8.56,0,0,1-2.71-.34,5.33,5.33,0,0,1-2-1.3,6.29,6.29,0,0,1-1.6-4.73,7.71,7.71,0,0,1,.91-3.55,6.48,6.48,0,0,1,2.26-2.41,9.21,9.21,0,0,1,5.07-1.28,19.72,19.72,0,0,1,2.12.1,4.6,4.6,0,0,1,1.41.28.09.09,0,0,1,.06.1c0,.1,0,.38-.09.82s-.08.67-.1.94c0,.08,0,.11-.08.11a.09.09,0,0,1-.1-.08,1.38,1.38,0,0,0-.11-.28,2.88,2.88,0,0,0-.82-.67,7,7,0,0,0-3-.52,5.51,5.51,0,0,0-4.14,1.59,6.26,6.26,0,0,0-1.74,4.52,6,6,0,0,0,1.83,4.66,5.26,5.26,0,0,0,1.88,1.16,7.8,7.8,0,0,0,2.28.27,4.88,4.88,0,0,0,2.07-.47,3.41,3.41,0,0,0,1.48-1.21,2.27,2.27,0,0,0,.33-.82c0-.11,0-.17.06-.17s.05,0,.07.09a2.67,2.67,0,0,0,.21.62C808.12,592.89,808.21,593.06,808.32,593.27Z"
            transform="translate(-172.86 -369.69)"
          />
        </g>
      </g>
    </svg>
  )
}
