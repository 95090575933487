import React from 'react'
import { NavbarMenuItem } from '../../types/navbar-menu-item'
import { MENU_ITEMS } from '../../static-data/menu-items'
import usePageTarget from '../../contexts/currentTargetContext'

export interface NavListProps {
  active?: string
  linkClicked?: Function
}

export const NavList: React.VFC<NavListProps> = ({ linkClicked }) => {
  const { scrollTo, currentTarget } = usePageTarget()

  const navItems: NavbarMenuItem[] = MENU_ITEMS
  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, target: string) => {
    e.preventDefault()
    linkClicked && linkClicked()
    scrollTo(target)
    // return false
  }
  return (
    <>
      {navItems.map((it) => (
        <a
          className={`text-white text-2xl lg:text-base font-bold py-4 px-2 hover:text-gray-100 `}
          key={it.href}
          href={it.href}
          onClick={(e) => onLinkClick(e, it.href)}
        >
          <span
            className={`border-b-2 border-solid pb-2  leading-4 hover:border-gray-100 ${
              it.href?.includes(currentTarget) ? 'border-primary' : 'border-transparent'
            } `}
          >
            {it.label}
          </span>
        </a>
      ))}
    </>
  )
}
