import React from 'react'
import './iconStyles.css'

// import icons from '../../icons'
export interface IconProps {
  name: string
  classes?: string
}

export const Icon: React.VFC<IconProps> = ({ name, classes = '' }) => {
  const fileName = ''
  return (
    <svg className={'icon mx-1 mb-1 ' + classes ?? ''}>
      <use xlinkHref={`/images/symbol-defs.svg${fileName}#${name}`}></use>
    </svg>
  )
}
