import React, { useMemo, VFC } from 'react'
import { WithClasses } from '../../../types/props-with-children-and-classes'
import { MainProjectInfo } from '../MainProjectInfo'
import data from './data.json'
import { getImagesToDisplay, imageSrc } from '../utils'
import { ImageWrapper } from '../ImageWrapper'
import { ImagesData } from '../models/ImagesData.interface'
import { ComponentWithGallery } from '../models/component-with-gallery.type'

export type DocumentPortalGalleryProps = WithClasses<ComponentWithGallery & {}>

export const DocumentPortalGallery: VFC<DocumentPortalGalleryProps> = (props) => {
  const { images, baseRef } = data as ImagesData
  const { classes, openModal } = props
  const visibleImages = useMemo(() => getImagesToDisplay(images), [])

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 max-w-screen-2xl m-auto">
        {[...visibleImages.slice(0, 1), null, ...visibleImages.slice(1)].map((imageToDisplay) => {
          if (null === imageToDisplay) {
            return (
              <MainProjectInfo
                classes={`-order-1 xl:order-1`}
                key={'xxx'}
                onClick={() => openModal(0, images, baseRef)}
                span={1}
                header={`Document Portal`}
                description={`This portal allows for uploads from disparate service locations, which then synced to our clients Google Docs. This eliminated the need for constant road trips to pick up the actual hard copies of these vital time-sensitive documents.`}
              />
            )
          } else {
            const { index, image } = imageToDisplay
            return (
              <ImageWrapper
                classes={`order-1`}
                key={index}
                src={imageSrc(baseRef, image.name)}
                click={(src) => openModal(index, images, baseRef)}
              />
            )
          }
        })}
      </div>
    </>
  )
}
